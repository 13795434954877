import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import axios from 'axios';
import authService from '../services/authService';
import { Rating } from 'primereact/rating';
import { useTranslation } from 'react-i18next';

const UserOrders = () => {
    const { t } = useTranslation();
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [review, setReview] = useState('');
    const [rating, setRating] = useState(0);
    const [userReviews, setUserReviews] = useState([]);
    const token = authService.getToken();
    const customerId = authService.getUserId();

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`https://api.artistarsapp.com/api/orders/customer/${customerId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const sortedOrders = response.data.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));

                setOrders(sortedOrders);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        const fetchUserReviews = async () => {
            try {
                const response = await axios.get(`https://api.artistarsapp.com/api/ratings/customer/${customerId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setUserReviews(response.data);
            } catch (error) {
                console.error('Error fetching user reviews:', error);
            }
        };

        fetchOrders();
        fetchUserReviews();
    }, [customerId, token]);

    const renderOrderDetails = (order) => {
        const userReview = userReviews.find(review => review.product.id === order.item.product.id);
        return (
            <div>
                <img src={`https://api.artistarsapp.com${order.item.product?.imageUrls[0]}`} alt={order.item.product?.name || t('UserOrders_product_not_available')} className="w-full h-48 object-cover mb-4 rounded-lg shadow-md" />
                <p><strong>{t('UserOrders_order_id')}:</strong> {order.id}</p>
                <p><strong>{t('UserOrders_product_name')}:</strong> {order.item.product?.name || t('UserOrders_product_not_available')}</p>
                <p><strong>{t('UserOrders_quantity')}:</strong> {order.item.quantity}</p>
                <p><strong>{t('UserOrders_total_price')}:</strong> {order.item.product?.price * order.item.quantity} KWD</p>
                {order.status === 'تم الشحن' && (
                    <>
                        <p><strong>{t('UserOrders_driver_name')}:</strong> {order.driverName || 'غير متوفر'}</p>
                        <Button
                            label={t('UserOrders_call_driver')}
                            icon="pi pi-phone"
                            className="p-button-rounded bg-gradient-to-r from-gray-700 to-black text-white hover:opacity-90 transition-opacity duration-300 mt-2 w-full"
                            onClick={() => window.location.href = `tel:${order.driverPhone}`}
                        />
                    </>
                )}
                {order.status === 'تم التوصيل' && (
                    <>
                        <Rating value={userReview ? userReview.score : rating} onChange={(e) => setRating(e.value)}
                            cancelIcon={<img src="https://primefaces.org/cdn/primereact/images/rating/cancel.png" alt="إلغاء التقييم" width="25px" height="25px" />}
                            onIcon={<img src="https://primefaces.org/cdn/primereact/images/rating/custom-icon-active.png" alt="تقييم مفعل" width="25px" height="25px" />}
                            offIcon={<img src="https://primefaces.org/cdn/primereact/images/rating/custom-icon.png" alt="تقييم غير مفعل" width="25px" height="25px" />}
                        />
                        <textarea value={userReview ? userReview.comment : review} onChange={(e) => setReview(e.target.value)} placeholder={t('UserOrders_review')} className="w-full p-2 border rounded mt-2"></textarea>
                        <Button label={userReview ? t('UserOrders_edit_review') : t('UserOrders_submit_review')} className="p-button-rounded bg-green-500 text-white mt-2 w-full" disabled={rating < 1 || review.trim() === ''} onClick={() => submitReview(order.item.product.id)} />
                        <h3 className="text-xl font-bold mt-6 mb-2">{t('UserOrders_reviews_and_comments')}</h3>

                        {renderReviews(userReviews.filter(review => review.product.id === selectedOrder.item.product.id))}

                    </>
                )}
            </div>
        );
    };

    const renderReviews = (reviews) => {
        return reviews ? reviews.map((review) => (
            <div key={review.id} className="border p-4 mb-2 rounded-lg shadow-sm">
                <div className="flex justify-between items-center mb-2">
                    <Rating value={review.score} readOnly cancel={false} className="mr-2" />
                    <div className="flex">
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-warning mr-2" onClick={() => editReview(review)} />
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger" onClick={() => deleteReview(review.id)} />
                    </div>
                </div>
                <p>{review.comment}</p>
            </div>
        )) : <p>{t('UserOrders_no_reviews')}</p>;
    };

    const submitReview = async (productId) => {
        const userReview = userReviews.find(review => review.product.id === productId);
        if (userReview) {
            // Update existing review
            try {
                const response = await axios.put(`https://api.artistarsapp.com/api/ratings/${userReview.id}`, {
                    score: rating,
                    comment: review,
                    customer: { id: customerId },
                    product: { id: productId }
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                console.log('Review updated', response.data);
                setUserReviews(userReviews.map(r => r.id === userReview.id ? response.data : r));
            } catch (error) {
                console.error('Error updating review:', error);
            }
        } else {
            // Create new review
            try {
                const response = await axios.post(`https://api.artistarsapp.com/api/ratings`, {
                    score: rating,
                    comment: review,
                    customer: { id: customerId },
                    product: { id: productId }
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                console.log('Review submitted', response.data);
                setUserReviews([...userReviews, response.data]);
            } catch (error) {
                console.error('Error submitting review:', error);
            }
        }
        setSelectedOrder(null);  // Close the dialog after submission
    };

    const editReview = (review) => {
        setReview(review.comment);
        setRating(review.score);
        // Logic to handle editing the review
    };

    const deleteReview = async (reviewId) => {
        try {
            await axios.delete(`https://api.artistarsapp.com/api/ratings/${reviewId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log(`Review with id ${reviewId} deleted`);
            setUserReviews(userReviews.filter(review => review.id !== reviewId));
        } catch (error) {
            console.error('Error deleting review:', error);
        }
    };

    return (
        <div className="container mx-auto p-6 text-right">
            <h2 className="text-3xl font-bold mb-6 text-center text-white">{t('UserOrders_title')}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {orders.map((order) => (
                    order.items.map((item) => (
                        <div key={item.id} className=" bg-[#ece5df] text-black shadow-md rounded-lg overflow-hidden w-80">
                            <img src={`https://api.artistarsapp.com${item.product?.imageUrls[0].replace("/home/ec2-user/uploads/", "/uploads/") || t('UserOrders_placeholder_img')}`} alt={item.product?.name || t('UserOrders_product_not_available')} className="w-full h-48 object-cover" />
                            <div className="p-4">
                                <h3 className="text-xl font-bold mb-2">{t('UserOrders_order_id')}: {order.id}</h3>
                                <p><strong>{t('UserOrders_product_name')}:</strong> {item.product?.name || t('UserOrders_product_not_available')}</p>
                                <p><strong>{t('UserOrders_quantity')}:</strong> {item.quantity}</p>
                                <p className={`mb-2 ${order.status === 'قيد المعالجة' ? 'text-yellow-500' : order.status === 'تم الشحن' ? 'text-blue-500' : 'text-green-500'}`}><strong>{t('UserOrders_status')}:</strong> {order.status}</p>
                                <p><strong>{t('UserOrders_total_price')}:</strong> {item.product?.price * item.quantity} KWD</p>
                                <Button label={t('UserOrders_view_details')} className="flex items-center justify-center space-x-2 space-x-reverse bg-gradient-to-r from-gray-700 to-gray-500 px-4 py-2 rounded-full hover:opacity-90 transition-opacity duration-300 mt-3 text-white" onClick={() => setSelectedOrder({ ...order, item })} />
                            </div>
                        </div>
                    ))
                ))}
            </div>
            <Dialog header={t('UserOrders_order_details')} visible={selectedOrder !== null} onHide={() => setSelectedOrder(null)}
              contentStyle={{ background: '#ece5df' }}
              headerStyle={{ background: '#ece5df', color: 'black' }}
            className="rtl">
                {selectedOrder && (
                    <div className="p-4 text-white">
                        {renderOrderDetails(selectedOrder)}
                    </div>
                )}
            </Dialog>
        </div>
    );
};

export default UserOrders;
