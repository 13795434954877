import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaShoppingCart, FaHome, FaAddressBook, FaClipboardList, FaCalendarAlt, FaStar, FaSignOutAlt, FaWallet, FaPaintBrush, FaCut, FaUserTie } from 'react-icons/fa'; // استيراد أيقونات إضافية
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import axios from 'axios';
import authService from '../services/authService';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import postsImage from '../assets/images/nv.png';

const Navbar = ({ currentUser, onLogout }) => {
    const { t } = useTranslation();
    const [nav, setNav] = useState(false);
    const [showLogoutDialog, setShowLogoutDialog] = useState(false);
    const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
    const [artistsDropdownOpen, setArtistsDropdownOpen] = useState(false);
    const [cartItemCount, setCartItemCount] = useState(0);
    const [fullname, setFullname] = useState(''); // حالة لتخزين اسم المستخدم

    const navigate = useNavigate();
    const profileDropdownRef = useRef(null);
    const artistsDropdownRef = useRef(null);

    useEffect(() => {
        const fetchCartItems = async () => {
            try {
                const customerId = authService.getUserId();
                const token = localStorage.getItem('token');
                // جلب بيانات سلة التسوق
                const response = await axios.get(`https://api.artistarsapp.com/api/carts/customer/${customerId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const cartId = response.data.id;
                const itemsResponse = await axios.get(`https://api.artistarsapp.com/api/carts/${cartId}/items`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setCartItemCount(itemsResponse.data.length);

                // جلب بيانات المستخدم
                const userResponse = await axios.get(`https://api.artistarsapp.com/api/users/customer/${customerId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setFullname(userResponse.data.fullname); // تعيين اسم المستخدم

            } catch (error) {
                console.error('Error fetching cart items or user data:', error);
            }
        };

        fetchCartItems();
        // إضافة مستمع للأحداث لإغلاق القائمة المنسدلة عند النقر خارجها
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleNav = () => setNav(!nav);
    const handleLogout = () => {
        onLogout();
        navigate('/login');
    };

    const confirmLogout = () => {
        setShowLogoutDialog(false);
        handleLogout();
    };

    const handleClickOutside = (event) => {
        if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
            setProfileDropdownOpen(false);
        }
        if (artistsDropdownRef.current && !artistsDropdownRef.current.contains(event.target)) {
            setArtistsDropdownOpen(false);
        }
    };

    const guestLinks = [
        { to: '/', text: t('home'), icon: FaHome },
        { to: '/contact', text: t('contact'), icon: FaAddressBook },
        { to: '/login', text: t('login'), icon: FaUser },
        { to: '/register', text: t('register'), icon: FaUser },
    ];

    const userLinks = [
        { to: '/', text: t('home'), icon: FaHome },
        { to: '/contact', text: t('contact'), icon: FaAddressBook },
    ];

    const artistLinks = [
        { to: '/makeup_artist', text: t('makeup_artists'), icon: FaPaintBrush },
        { to: '/hairstulist_artist', text: t('hair_stylists'), icon: FaCut },
        { to: '/artists', text: t('models'), icon: FaUserTie },
    ];

    const profileLinks = [
        { to: '/profile', text: t('profile'), icon: FaUser },
        { to: '/addresses', text: t('addresses'), icon: FaAddressBook },
        { to: '/orders', text: t('orders'), icon: FaClipboardList },
        { to: '/appointments', text: t('appointments'), icon: FaCalendarAlt },
        { to: '/favorite-artists', text: t('favorite_artists'), icon: FaStar },
        { to: '/my-wallet', text: t('wallet'), icon: FaWallet },
        { to: '#', text: t('logout'), icon: FaSignOutAlt, onClick: () => setShowLogoutDialog(true) },
    ];

    const links = currentUser ? userLinks : guestLinks;

    return (
        <div className="sticky top-0 w-full h-20 shadow-lg z-[100] bg-black text-white">
            <div className="flex justify-between items-center w-full h-full px-4 md:px-10 2xl:px-16">
                <Link to="/" className="flex items-center">
                    <img src={postsImage} alt="Artesta Logo" className="h-[65px]" /> {/* تأكد من تعديل المسار إلى مسار الصورة الخاص بك */}
                </Link>
                <div className="flex items-center space-x-6">
                    <LanguageSwitcher />
                    <div className="hidden md:flex items-center space-x-8 mr-2">
                        {links.map(({ to, text, icon: Icon }, index) => (
                            <Link
                                key={to}
                                to={to}
                                className={`text-sm uppercase flex items-center ${index === 0 ? 'ml-4' : 'mr-2'} space-x-2 space-x-reverse group transition duration-300`}
                            >
                                <Icon size={20} />
                                <span className="group-hover:text-gray-400 transition duration-300">{text}</span>
                            </Link>
                        ))}
                        {currentUser && (
                            <>
                                <div className="relative" ref={artistsDropdownRef}>
                                    <button
                                        onClick={() => setArtistsDropdownOpen(!artistsDropdownOpen)}
                                        className="text-sm uppercase flex items-center space-x-2 space-x-reverse group transition duration-300"
                                    >
                                        <FaUser size={20} />
                                        <span className="group-hover:text-gray-400 transition duration-300">{t('artists')}</span>
                                    </button>
                                    {artistsDropdownOpen && (
                                        <div className="absolute right-0 bg-white text-black p-2 shadow-lg mt-2 rounded z-50">
                                            {artistLinks.map(({ to, text, icon: Icon }) => (
                                                <Link key={to} to={to} className="text-sm flex items-center space-x-2 space-x-reverse p-2 hover:bg-gray-200 w-full">
                                                    <Icon size={20} />
                                                    <span>{text}</span>
                                                </Link>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="relative" ref={profileDropdownRef}>
                                    <button
                                        onClick={() => setProfileDropdownOpen(!profileDropdownOpen)}
                                        className="text-sm uppercase flex items-center space-x-2 space-x-reverse group transition duration-300"
                                    >
                                        <FaUser size={20} />
                                        <span className="group-hover:text-gray-400 transition duration-300">{t('profile')}</span>
                                    </button>
                                    {profileDropdownOpen && (
                                        <div className="absolute right-0 bg-white text-black p-2 shadow-lg mt-2 rounded z-50">
                                            {profileLinks.map(({ to, text, icon: Icon, onClick }) => (
                                                <Link key={to} to={to} onClick={onClick} className="text-sm flex items-center space-x-2 space-x-reverse p-2 hover:bg-gray-200 w-full">
                                                    <Icon size={20} />
                                                    <span>{text}</span>
                                                </Link>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        <Link to="/cart" className="relative flex items-center group">
                            <FaShoppingCart className="cursor-pointer text-white hover:text-gray-300" size={20} />
                            {cartItemCount > 0 && (
                                <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs px-1">{cartItemCount}</span>
                            )}
                        </Link>
                    </div>
                    <div className="md:hidden cursor-pointer" onClick={handleNav}>
                        <AiOutlineMenu size={25} className="text-white" />
                    </div>
                </div>
            </div>

            {nav && (
    <div className="fixed inset-0 bg-black/70">
        <div className="fixed right-0 top-0 w-[75%] sm:w-[60%] md:w-[45%] h-screen bg-black p-10 ease-in duration-500 text-white">
            <div className="flex w-full items-center justify-between">
                <Link to="/" onClick={handleNav} className="text-2xl font-bold text-white">
                <img src={postsImage} alt="Artesta Logo" className="h-[150px]" /> {/* تأكد من تعديل المسار إلى مسار الصورة الخاص بك */}
                </Link>
                <div onClick={handleNav} className="cursor-pointer">
                    <AiOutlineClose size={25} className="text-white" />
                </div>
            </div>
            <div className="border-b border-gray-600 my-4">
                {currentUser && <p className="w-[85%] md:w-[90%] py-4 text-right">{t('welcome')} {fullname}</p>}
            </div>
            <ul className="space-y-4 text-right">
                {links.map(({ to, text, icon: Icon }) => (
                    <li key={to}>
                        <Link to={to} onClick={handleNav} className="text-sm uppercase flex items-center space-x-2 space-x-reverse">
                            <Icon size={20} />
                            <span>{text}</span>
                        </Link>
                    </li>
                ))}
                {currentUser && (
                    <>
                        <li>
                            <Link to="/profile" onClick={handleNav} className="text-sm uppercase flex items-center space-x-2 space-x-reverse">
                                <FaUser size={20} />
                                <span>{t('profile')}</span>
                            </Link>
                        </li>
                        <li>
                        <Link to="/cart" className="text-sm uppercase flex items-center space-x-2 space-x-reverse">
                            <FaShoppingCart className="cursor-pointer text-white hover:text-gray-300" size={20} />
                            <span>{t('cart')}</span>
                            {cartItemCount > 0 && (
                                <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs px-1">{cartItemCount}</span>
                            )}
                        </Link>
                        </li>
                        <li>
                            <Link to="/addresses" onClick={handleNav} className="text-sm uppercase flex items-center space-x-2 space-x-reverse">
                                <FaAddressBook size={20} />
                                <span>{t('addresses')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/orders" onClick={handleNav} className="text-sm uppercase flex items-center space-x-2 space-x-reverse">
                                <FaClipboardList size={20} />
                                <span>{t('orders')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/appointments" onClick={handleNav} className="text-sm uppercase flex items-center space-x-2 space-x-reverse">
                                <FaCalendarAlt size={20} />
                                <span>{t('appointments')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/favorite-artists" onClick={handleNav} className="text-sm uppercase flex items-center space-x-2 space-x-reverse">
                                <FaStar size={20} />
                                <span>{t('favorite_artists')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/my-wallet" onClick={handleNav} className="text-sm uppercase flex items-center space-x-2 space-x-reverse">
                                <FaWallet size={20} />
                                <span>{t('wallet')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={() => { setShowLogoutDialog(true); handleNav(); }} className="text-sm uppercase flex items-center space-x-2 space-x-reverse">
                                <FaSignOutAlt size={20} />
                                <span>{t('logout')}</span>
                            </Link>
                        </li>
                    </>
                )}
            </ul>
        </div>
    </div>
)}

<Dialog header={t('logout')} visible={showLogoutDialog} style={{ width: '50vw' }} onHide={() => setShowLogoutDialog(false)}>
    <p>{t('confirm_logout')}</p>
    <div className="flex justify-end">
        <Button label={t('cancel')} icon="pi pi-times" onClick={() => setShowLogoutDialog(false)} className="p-button-text" />
        <Button label={t('confirm')} icon="pi pi-check" onClick={confirmLogout} autoFocus />
    </div>
</Dialog>
        </div>
    );
};

export default Navbar;
