// src/services/authService.js
class AuthService {
  login(token) {
      localStorage.setItem('token', token);
      const user = this.decodeToken(token);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userId', user.id);  // تخزين معرف العميل
  }

  logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('userId');  // إزالة معرف العميل
  }

  getCurrentUser() {
      return JSON.parse(localStorage.getItem('user'));
  }

  getToken() {
      return localStorage.getItem('token');
  }

  getUserId() {
      return localStorage.getItem('userId');  // الحصول على معرف العميل
  }

  decodeToken(token) {
      try {
          const payload = JSON.parse(atob(token.split('.')[1]));
          return {
              username: payload.sub,
              roles: payload.roles,
              id: payload.id  // استخراج معرف العميل
          };
      } catch (e) {
          return null;
      }
  }
}

const authService = new AuthService();
export default authService;
