import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import authService from '../services/authService';
import { useTranslation } from 'react-i18next';

const Login = ({ onLogin }) => {
    const navigate = useNavigate();
    const [form, setForm] = useState({ username: '', password: '' });
    const [errors, setErrors] = useState({});
    const { t } = useTranslation();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!form.username) formErrors.username = t('Login_username_required');
        if (!form.password) formErrors.password = t('Login_password_required');
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const submitLogin = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const response = await axios.post('https://api.artistarsapp.com/api/users/login/customer', form);
                const { jwt } = response.data;
                authService.login(jwt);
                onLogin();
                navigate('/');
            } catch (error) {
                console.error('Error logging in:', error);
                setErrors({ form: t('Login_error') });
            }
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen ">
            <div className=" bg-[#ece5df] rounded-xl shadow-lg   px-8 pt-6 pb-8 mb-4 flex flex-col w-full max-w-md">
                <h2 className="text-2xl font-bold mb-6 text-center">{t('Login_title')}</h2>
                <form onSubmit={submitLogin}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                            {t('Login_username_label')}
                        </label>
                        <InputText id="username" name="username" type="text" placeholder={t('Login_username_placeholder')} value={form.username} onChange={handleInputChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" />
                        {errors.username && <p className="text-red-500 text-xs italic">{errors.username}</p>}
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            {t('Login_password_label')}
                        </label>
                        <InputText id="password" name="password" type="password" placeholder={t('Login_password_placeholder')} value={form.password} onChange={handleInputChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3" />
                        {errors.password && <p className="text-red-500 text-xs italic">{errors.password}</p>}
                    </div>
                    <div className="flex items-center justify-between">
                        <Button label={t('Login_button')}  className="mt-4 bg-gradient-to-r from-gray-700 to-gray-500 text-white px-4 py-2 rounded-lg" onClick={submitLogin} />
                    </div>
                    {errors.form && <p className="text-red-500 text-xs italic mt-4">{errors.form}</p>}
                </form>
                <p className="text-center mt-4">
                    {t('Login_no_account')} <a href="#" onClick={() => navigate('/register')} className="text-blue-600 hover:underline">{t('Login_register')}</a>
                </p>
            </div>
        </div>
    );
};

export default Login;
