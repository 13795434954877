import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-black  text-white text-center p-4">
      <p>© 2024 Artistars. {t('Footer_text')}</p>
      </footer>
  );
}

export default Footer;
