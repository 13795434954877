import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';
import { Galleria } from 'primereact/galleria';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Avatar } from 'primereact/avatar';
import authService from '../services/authService';
import { useTranslation } from 'react-i18next';

const ProductDetail = () => {
  const navigate = useNavigate();
  const isUserLoggedIn = authService.getToken() !== null; // تحقق مما إذا كان المستخدم مسجلاً دخول


  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [reviews, setReviews] = useState([]);
  const toast = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productResponse = await axios.get(`https://api.artistarsapp.com/api/products/${productId}`, );

        setProduct(productResponse.data);

        const reviewsResponse = await axios.get(`https://api.artistarsapp.com/api/ratings/product/${productId}`, );

        const sortedReviews = reviewsResponse.data.sort((a, b) => new Date(b.id) - new Date(a.id));
        setReviews(sortedReviews);
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };

    fetchProduct();
  }, [productId]);

  const handleAddToCart = async (product) => {
    try {
      const token = localStorage.getItem('token');
      const customerId = authService.getUserId();
      const response = await axios.get(`https://api.artistarsapp.com/api/carts/customer/${customerId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const cart = response.data;
      const cartItemsResponse = await axios.get(`https://api.artistarsapp.com/api/carts/${cart.id}/items`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const cartItems = cartItemsResponse.data;

      if (cartItems.length > 0) {
        const artistIdInCart = cartItems[0].product.artist.id;
        if (artistIdInCart !== product.artist.id) {
          toast.current.show({ severity: 'warn', summary: t('ProductDetail_warning'), detail: t('ProductDetail_warning_detail'), life: 3000 });
          return;
        }
      }

      const newCartItem = { product: { id: product.id }, quantity: 1 };
      await axios.post(`https://api.artistarsapp.com/api/carts/${cart.id}/items`, newCartItem, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      toast.current.show({ severity: 'success', summary: t('ProductDetail_success'), detail: t('ProductDetail_success_detail'), life: 3000 });
    } catch (error) {
      console.error('Error adding to cart:', error);
      toast.current.show({ severity: 'error', summary: t('ProductDetail_error'), detail: t('ProductDetail_error_detail'), life: 3000 });
    }
  };

  const itemTemplate = (item) => {
    return <img src={`https://api.artistarsapp.com${item.replace("/home/ec2-user/uploads/", "/uploads/")}`} alt="ProductImage" className="w-full h-64 object-contain rounded-lg shadow-md" />;
  };

  const getSeverity = (product) => {
    switch (product.quantity) {
      case 0:
        return 'danger';
      case 1:
      case 2:
        return 'warning';
      default:
        return 'success';
    }
  };

  const calculateAverageRating = (reviews) => {
    if (reviews.length === 0) return 0;
    const total = reviews.reduce((sum, review) => sum + review.score, 0);
    const average = total / reviews.length;
    return Math.round(average);
  };

  const starTemplate = (value) => {
    return (
      <i className="pi pi-star-fill" style={{ color: '#FFD700', fontSize: '1.5rem' }}></i>
    );
  };

  const starTemplateOff = (value) => {
    return (
      <i className="pi pi-star-fill" style={{ color: '#e5e2d2', fontSize: '1.5rem' }}></i>
    );
  };

  const getAvatar = (review) => {
    if (review.customer && review.customer.avatar) {
      return `https://api.artistarsapp.com${review.customer.avatar.replace("/home/ec2-user/uploads/", "/uploads/")}`;
    } else {
      return "https://via.placeholder.com/40";
    }
  };

  return (
    <div className="container mx-auto p-6 bg-[#ece5df] text-black rounded-lg shadow-lg">
      <Toast ref={toast} />
      {product && (
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-1/2">
            <Galleria className="mt-2 mb-2" value={product.imageUrls} numVisible={1} circular style={{ maxWidth: '640px' }}
              showThumbnails={false} showItemNavigators item={itemTemplate} />
          </div>
          <div className="w-full lg:w-1/2 flex flex-col justify-center">
            <h1 className="text-4xl font-bold mb-4 text-black">{product.name}</h1>
            <div className="mb-4">
              <Rating value={parseFloat(calculateAverageRating(reviews))} readOnly cancel={false} onIcon={starTemplate} offIcon={starTemplateOff} />
            </div>
            <p className="text-xl text-black mb-4">{t('ProductDetail_price', { price: product.price })}</p>
            <p className="text-black mb-4">{product.description}</p>
            <div className="flex items-center gap-3 mb-6">
              <Tag value={product.quantity > 0 ? t('ProductDetail_available') : t('ProductDetail_not_available')} severity={getSeverity(product)}></Tag>
            </div>
            <Button
    label={t('ProductDetail_add_to_cart')}
    icon="pi pi-shopping-cart"
    className="flex items-center justify-center space-x-2 space-x-reverse bg-gradient-to-r from-gray-700 to-gray-500 px-4 py-2 rounded-full hover:opacity-90 transition-opacity duration-300 text-white"
    disabled={product.quantity === 0}
    onClick={() => {
        if (!isUserLoggedIn) {
            navigate('/login');
        } else {
            handleAddToCart(product);
        }
    }}
/>

          </div>
        </div>
      )}
      <div className="mt-8 bg-[#ece5df] rounded-xl shadow-lg  p-6 ">
        <h2 className="text-2xl font-semibold mb-4 text-center">{t('ProductDetail_reviews')}</h2>
        {reviews.length > 0 ? (
          reviews.map(review => (
            <div key={review.id} className=" rounded-lg p-4 mb-4 shadow-sm bg-[#ece5df]  text-black">
              <div className="flex items-center mb-2">
                <Avatar 
                  image={getAvatar(review)} 
                  className="ml-2 mb-2" 
                  shape="circle" 
                  style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                />
                <div>
                  <p className="font-bold text-black">{review.fakeUsername || (review.customer && review.customer.fullname) || t('ProductDetail_anonymous_user')}</p>
                  <div>
                    <Rating value={review.score} readOnly cancel={false} onIcon={starTemplate} offIcon={starTemplateOff} />
                  </div>
                </div>
              </div>
              <p className="text-black">{review.comment}</p>
            </div>
          ))
        ) : (
          <p>{t('ProductDetail_no_reviews')}</p>
        )}
      </div>
    </div>
  );
};

export default ProductDetail;
