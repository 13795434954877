import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Cart from './pages/Cart';
import ArtistProfile from './pages/ArtistProfile';
import ProductList from './pages/ProductList';
import ProductDetail from './pages/ProductDetail';
import ArtistsPage from './pages/ArtistsPage';
import ArtistAppointments from './pages/ArtistAppointments';
import ArtistProducts from './pages/ArtistProducts';
import UserProfile from './pages/UserProfile';
import UserAddresses from './pages/UserAddresses';
import UserOrders from './pages/UserOrders';
import UserAppointments from './pages/UserAppointments';
import FavoriteArtists from './pages/FavoriteArtists';
import Login from './pages/Login';
import Register from './pages/Register';
import authService from './services/authService';
import PrivateRoute from './components/PrivateRoute';
import PostsPage from './pages/PostsPage';
import Categories from './pages/Categories';
import ClientWallet from './pages/ClientWallet';
import MakeUpArtistPage from './pages/MakeUpArtistPage';
import HairStulistPage from './pages/HairStulistPage';
import PaymentPage from './pages/PaymentPage';


import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

const App = () => {
  const [currentUser, setCurrentUser] = useState(authService.getCurrentUser());

  const handleLogin = () => {
    setCurrentUser(authService.getCurrentUser());
  };

  const handleLogout = () => {
    authService.logout();
    setCurrentUser(null);
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-black">
        <Navbar currentUser={currentUser} onLogout={handleLogout} />
        <div className="flex-grow p-4">
          <Routes>
            <Route path="/login" element={currentUser ? <Navigate to="/" /> : <Login onLogin={handleLogin} />} />
            <Route path="/register" element={currentUser ? <Navigate to="/" /> : <Register />} />
            <Route path="/" element={<Home />} />
            <Route path="/categories/:id" element={<Categories />} />

            <Route path="/cart" element={<Cart />} />
            <Route path="/artist/:id" element={<ArtistProfile />} />
            <Route path="/artist/:id/appointments" element={<ArtistAppointments />} />
            <Route path="/artist/:id/products" element={<ArtistProducts />} />
            <Route path="/products" element={<ProductList />} />
            <Route path="/artist/:id/posts" element={<PostsPage />} />
            <Route path="/product/:productId" element={<ProductDetail />} />
            <Route path="/artists" element={<ArtistsPage />} />
            <Route path="/makeup_artist" element={<MakeUpArtistPage />} />
            <Route path="/hairstulist_artist" element={<HairStulistPage />} />
            <Route element={<PrivateRoute currentUser={currentUser} />}>
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/addresses" element={<UserAddresses />} />
              <Route path="/orders" element={<UserOrders />} />
              <Route path="/my-wallet" element={<ClientWallet />} />
              <Route path="/appointments" element={<UserAppointments />} />
              <Route path="/favorite-artists" element={<FavoriteArtists />} />
              <Route path="/PaymentPage" element={<PaymentPage />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
