import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';
import { Galleria } from 'primereact/galleria';
import { Toast } from 'primereact/toast';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import authService from '../services/authService';

const ArtistProducts = () => {
    const isUserLoggedIn = authService.getToken() !== null; // تحقق مما إذا كان المستخدم مسجلاً دخول

    const { t } = useTranslation();
    const [products, setProducts] = useState([]);
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [layout, setLayout] = useState(window.innerWidth <= 768 ? 'grid' : 'grid');
    const toast = useRef(null);
    const [artistName, setArtistName] = useState('');

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const queryParams = new URLSearchParams(location.search);
                const categoryId = queryParams.get('category');

                const response = await axios.get(`https://api.artistarsapp.com/api/products/artist/${id}`);
                const filteredProducts = categoryId ? response.data.filter(product => product.category.id === parseInt(categoryId)) : response.data;
                setProducts(filteredProducts);

                const artistResponse = await axios.get(`https://api.artistarsapp.com/api/users/artist/${id}`);
                setArtistName(artistResponse.data.fullname);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, [id, location.search]);

    useEffect(() => {
        const handleResize = () => {
            setLayout(window.innerWidth <= 768 ? 'grid' : 'grid');
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getSeverity = (product) => {
        switch (product.quantity) {
            case 0:
                return 'danger';
            case 1:
            case 2:
                return 'warning';
            default:
                return 'success';
        }
    };

    const calculateAverageRating = (ratings) => {
        if (!ratings || ratings.length === 0) return 0;
        const total = ratings.reduce((sum, rating) => sum + rating.score, 0);
        return total / ratings.length;
    };

    const handleAddToCart = async (product) => {
        try {
            const token = localStorage.getItem('token');
            const customerId = authService.getUserId();
            const response = await axios.get(`https://api.artistarsapp.com/api/carts/customer/${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const cart = response.data;
            const cartItemsResponse = await axios.get(`https://api.artistarsapp.com/api/carts/${cart.id}/items`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const cartItems = cartItemsResponse.data;

            if (cartItems.length > 0) {
                const artistIdInCart = cartItems[0].product.artist.id;
                if (artistIdInCart !== product.artist.id) {
                    toast.current.show({ severity: 'warn', summary: t('ArtistProducts_warning'), detail: t('ArtistProducts_cart_add_warning'), life: 3000 });
                    return;
                }
            }

            const newCartItem = { product: { id: product.id }, quantity: 1 };
            await axios.post(`https://api.artistarsapp.com/api/carts/${cart.id}/items`, newCartItem, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            toast.current.show({ severity: 'success', summary: t('ArtistProducts_success'), detail: t('ArtistProducts_cart_add_success'), life: 3000 });
        } catch (error) {
            console.error('Error adding to cart:', error);
            toast.current.show({ severity: 'error', summary: t('ArtistProducts_error'), detail: t('ArtistProducts_cart_add_error'), life: 3000 });
        }
    };

    const itemTemplate = (item) => {
        return <img src={`https://api.artistarsapp.com${item.replace("/home/ec2-user/uploads/", "/uploads/")}`} alt={t('ArtistProducts_product_image')} className="w-full h-36 object-contain" />;
    };

    const starTemplate = (value) => {
        return (
            <i className="pi pi-star-fill text-yellow-500 text-sm"></i>
        );
    };

    const starTemplateOff = (value) => {
        return (
            <i className="pi pi-star-fill text-gray-300 text-sm"></i>
        );
    };

    const gridItem = (product) => {
        return (
            <div className="col-6 sm:col-4 lg:col-3 p-2" key={product.id}>
                <div className="rounded-lg shadow-md bg-[#ece5df] text-black transition-transform transform hover:scale-105 duration-300 h-full flex flex-col justify-between">
                    <div className="relative">
                        <Galleria className="mt-3" value={product.imageUrls} numVisible={1} circular showThumbnails={false} showItemNavigators item={itemTemplate} />
                    </div>
                    <div className="flex flex-col p-3 gap-1 flex-grow">
                        <div className="text-sm  cursor-pointer mt-2 mb-2" onClick={() => navigate(`/product/${product.id}`)}>{product.name}</div>
                        <Rating value={calculateAverageRating(product.ratings)} readOnly cancel={false} onIcon={starTemplate} offIcon={starTemplateOff}></Rating>
                        <div className="flex items-center gap-1 mt-2 mb-2">
                            <Tag value={product.quantity > 0 ? t('ArtistProducts_available') : t('ArtistProducts_not_available')} severity={getSeverity(product)}></Tag>
                            <span className="text-xs font-semibold">{t('ArtistProducts_product_price', { price: product.price })}</span>
                        </div>
                    </div>
                    <div className="p-3 pt-0">
                    <Button
    icon="pi pi-shopping-cart"
    label={t('ArtistProducts_add_to_cart')}
    className="flex items-center justify-center space-x-2 space-x-reverse bg-gradient-to-r from-gray-700 to-gray-500 px-2 py-1 text-white rounded-full hover:opacity-90 transition-opacity duration-300 w-full text-xs"
    disabled={product.quantity === 0}
    onClick={() => {
        if (!isUserLoggedIn) {
            navigate('/login');
        } else {
            handleAddToCart(product);
        }
    }}
/>

                    </div>
                </div>
            </div>
        );
    };

    const renderListItem = (product, layout) => {
        if (!product) {
            return null;
        }

        if (layout === 'grid') {
            return gridItem(product);
        }
    };

    const itemTemplateFunc = (product, layout) => {
        if (!product) {
            return null;
        }

        return renderListItem(product, layout);
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="my-4">
                <DataView
                    className="text-md font-bold custom-dataview"
                    value={products}
                    layout={layout}
                    itemTemplate={itemTemplateFunc}
                    paginator
                    rows={8}
                    header={
                        <div className="flex justify-between items-center text-white p-4 rounded-lg shadow-md">
                            <h2 className="text-md font-bold text-center">{t('ArtistProducts_artist_products', { artistName })}</h2>
                            <DataViewLayoutOptions className="my-4" layout={layout} onChange={(e) => setLayout(e.value)} />
                        </div>
                    }
                />
            </div>
        </div>
    );
};

export default ArtistProducts;
