import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';

const PaymentPage = () => {
    const [amount, setAmount] = useState(20); // المبلغ الكلي
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([
        { label: 'KNET', value: 'knet' },
        { label: 'VISA', value: 'cc' },
        { label: 'MASTER', value: 'cc' },
        { label: 'Samsung Pay', value: 'samsung-pay' },
        { label: 'Google Pay', value: 'google-pay' },
        { label: 'Apple Pay', value: 'apple-pay' },
    ]);
    const [cardNumber, setCardNumber] = useState('5123450000000008');
    const [expiryDate, setExpiryDate] = useState('01/39'); // تغيير إلى '05/39' للكرت الفاشل
    const [cvv, setCvv] = useState('100');
    const [resultMessage, setResultMessage] = useState(null);
    const toast = useRef(null);

    const handlePayment = async () => {
        try {
            const baseUrl = `${window.location.origin}${window.location.pathname}`;
            const response = await axios.post('https://sandboxapi.upayments.com/api/v1/charge', {
                products: [
                    {
                        name: "Logitech K380",
                        description: "Logitech K380 / Easy-Switch for Upto 3 Devices, Slim Bluetooth Tablet Keyboard",
                        price: 10,
                        quantity: 1
                    },
                    {
                        name: "Logitech M171 Wireless Optical Mouse",
                        description: "Logitech M171 Wireless Optical Mouse (2.4GHz Wireless, Blue Grey)",
                        price: 10,
                        quantity: 1
                    }
                ],
                order: {
                    id: "202210101255255144669",
                    reference: "11111991",
                    description: "Purchase order received for Logitech K380 Keyboard",
                    currency: "KWD",
                    amount: amount
                },
                paymentGateway: {
                    src: paymentMethod // استخدم طريقة الدفع المحددة
                },
                language: "en",
                reference: {
                    id: "202210101202210101"
                },
                customer: {
                    uniqueId: "2129879kjbljg767881",
                    name: "Dharmendra Kakde",
                    email: "kakde.dharmendra@upayments.com",
                    mobile: "+96566336537"
                },
                returnUrl: `${baseUrl}?status=success`,
                cancelUrl: `${baseUrl}?status=failure`,
                notificationUrl: "https://webhook.site/d7c6e1c8-b98b-4f77-8b51-b487540df336",
                customerExtraData: "User define data"
            }, {
                headers: {
                    'Authorization': `Bearer e66a94d579cf75fba327ff716ad68c53aae11528`,
                    'Accept': 'application/json'
                }
            });

            const { data } = response;
            if (data.status) {
                window.location.href = `${data.data.link}&redirect_status=true`;
            } else {
                setResultMessage({ severity: 'error', text: 'Payment failed' });
            }
        } catch (error) {
            setResultMessage({ severity: 'error', text: 'Payment failed' });
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const paymentStatus = queryParams.get('status');
        const redirectStatus = queryParams.get('redirect_status');

        if (paymentStatus || redirectStatus) {
            if (paymentStatus === 'success' || redirectStatus === 'true') {
                setResultMessage({ severity: 'success', text: 'Payment successful' });
            } else {
                setResultMessage({ severity: 'error', text: 'Payment failed' });
            }
        }
    }, []);

    return (
        <div className="container mx-auto mt-8">
            <Toast ref={toast} />
            <Dialog header="Make a Payment" visible={true} onHide={() => {}}>
                <div className="mb-4">
                    <label htmlFor="amount">Amount</label>
                    <InputNumber id="amount" value={amount} onValueChange={(e) => setAmount(e.value)} className="w-full" />
                </div>
                <div className="mb-4">
                    <label htmlFor="paymentMethod">Payment Method</label>
                    <Dropdown 
                        id="paymentMethod" 
                        value={paymentMethod} 
                        options={paymentMethods} 
                        onChange={(e) => setPaymentMethod(e.value)} 
                        placeholder="Select Payment Method" 
                        className="w-full" 
                    />
                </div>
                {(paymentMethod === 'cc') && (
                    <>
                        <div className="mb-4">
                            <label htmlFor="cardNumber">Card Number</label>
                            <InputText id="cardNumber" value={cardNumber} onChange={(e) => setCardNumber(e.target.value)} className="w-full" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="expiryDate">Expiry Date</label>
                            <InputText id="expiryDate" value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} className="w-full" placeholder="MM/YY" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="cvv">CVV</label>
                            <InputText id="cvv" value={cvv} onChange={(e) => setCvv(e.target.value)} className="w-full" />
                        </div>
                    </>
                )}
                <Button label="Pay" icon="pi pi-check" onClick={handlePayment} className="w-full" />
            </Dialog>
            {resultMessage && <Message severity={resultMessage.severity} text={resultMessage.text} />}
        </div>
    );
};

export default PaymentPage;
