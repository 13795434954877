import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Card } from 'primereact/card';
import { FaCcVisa, FaCcMastercard, FaPaypal, FaWallet } from 'react-icons/fa';
import authService from '../services/authService';
import { useTranslation } from 'react-i18next';

const ClientWallet = () => {
    const [wallet, setWallet] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [amount, setAmount] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingTransactions, setLoadingTransactions] = useState(true);
    const toast = useRef(null);
    const { t } = useTranslation();

    const paymentMethods = [
        { label: 'Visa', value: 'Visa', icon: <FaCcVisa className="mr-2 text-blue-600" /> },
        { label: 'MasterCard', value: 'MasterCard', icon: <FaCcMastercard className="mr-2 text-orange-600" /> },
        { label: 'PayPal', value: 'PayPal', icon: <FaPaypal className="mr-2 text-blue-600" /> },
    ];

    const token = authService.getToken();
    const customerId = authService.getUserId();

    useEffect(() => {
        fetchWallet();
        fetchTransactions();
    }, []);

    const fetchWallet = async () => {
        try {
            const response = await axios.get(`https://api.artistarsapp.com/api/client-wallets/customer/${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setWallet(response.data);
        } catch (error) {
            console.error('Error fetching wallet:', error);
            toast.current.show({ severity: 'error', summary: t('ClientWallet_error'), detail: t('ClientWallet_fetch_wallet_fail'), life: 3000 });
        } finally {
            setLoading(false);
        }
    };

    const fetchTransactions = async () => {
        try {
            const response = await axios.get(`https://api.artistarsapp.com/api/client-wallets/customer/${customerId}/recharge-records`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setTransactions(response.data.reverse());
        } catch (error) {
            console.error('Error fetching transactions:', error);
            toast.current.show({ severity: 'error', summary: t('ClientWallet_error'), detail: t('ClientWallet_fetch_transactions_fail'), life: 3000 });
        } finally {
            setLoadingTransactions(false);
        }
    };

    const rechargeWallet = async () => {
        try {
            await axios.put(`https://api.artistarsapp.com/api/client-wallets/customer/${customerId}/add-funds`, null, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    amount,
                    paymentMethod,
                    type: t('ClientWallet_recharge'),
                    description: t('ClientWallet_wallet_recharge')
                }
            });
            toast.current.show({ severity: 'success', summary: t('ClientWallet_success'), detail: t('ClientWallet_wallet_recharge_success'), life: 3000 });
            setShowDialog(false);
            fetchWallet();
            fetchTransactions();
        } catch (error) {
            console.error('Error recharging wallet:', error);
            toast.current.show({ severity: 'error', summary: t('ClientWallet_error'), detail: t('ClientWallet_wallet_recharge_fail'), life: 3000 });
        }
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-KW', { style: 'currency', currency: 'KWD' });
    };

    const formatDate = (timestamp) => {
        const [year, month, day, hour, minute, second] = timestamp;
        return new Date(year, month - 1, day, hour, minute, second).toLocaleString();
    };

 



    const descriptionTemplate = (rowData) => {
        return rowData.type === 'خصم' ? `خصم: ${rowData.description}` : rowData.description;
    };

    const amountTemplate = (rowData) => {
        return rowData.type === 'خصم' 
            ? <span className="text-red-500">-{formatCurrency(rowData.amount)}</span>
            : <span className="text-green-500">+{formatCurrency(rowData.amount)}</span>;
    };

    const rowClassName = (rowData) => {
        return {
            'bg-red-100': rowData.type === 'خصم',
            'bg-green-100': rowData.type === 'شحن'
        };
    };

    const paymentMethodTemplate = (rowData) => {
        let icon;
        switch (rowData.paymentMethod) {
            case 'Visa':
                icon = <FaCcVisa className="text-blue-600" />;
                break;
            case 'MasterCard':
                icon = <FaCcMastercard className="text-orange-600" />;
                break;
            case 'PayPal':
                icon = <FaPaypal className="text-blue-600" />;
                break;
            case 'Wallet':
                icon = <FaWallet className="text-green-600" />;
                break;
            default:
                icon = null;
        }
        return (
            <div className="flex items-center gap-2">
                {icon}
                <span>{rowData.paymentMethod}</span>
            </div>
        );
    };

    return (
        <div className="container mx-auto mt-8">
            <Toast ref={toast} />
            <h2 className="text-3xl font-bold mb-4 text-center text-white">{t('ClientWallet_my_wallet')}</h2>
            {loading ? (
                <div className="flex justify-center items-center h-64">
                    <ProgressSpinner />
                </div>
            ) : wallet ? (
                <Card className="mb-4 p-6 bg-[#ece5df] text-black rounded-lg shadow-md">
                    <div className="text-center text-md font-bold">
                        <p>{t('ClientWallet_current_balance')}: {formatCurrency(wallet.balance)}</p>
                        <Button label={t('ClientWallet_recharge_wallet')} icon="pi pi-plus" className="mt-4 bg-gradient-to-r from-gray-700 to-gray-500 text-white px-4 py-2 rounded-lg" onClick={() => setShowDialog(true)} />
                    </div>
                </Card>
            ) : (
                <p className="text-center text-white">{t('ClientWallet_no_wallet')}</p>
            )}
            <Dialog header={t('ClientWallet_recharge_wallet')} visible={showDialog}
              contentStyle={{ background: '#ece5df' }}
              style={{ width: '80vw' }} // تغيير العرض إلى 70% من عرض الشاشة

              headerStyle={{ background: '#ece5df', color: 'black' }}
            
            onHide={() => setShowDialog(false)} className="w-1/2 lg:w-1/3 mx-auto  rounded-lg shadow-lg p-4">
                <div className="mb-4">
                    <label htmlFor="amount" className="block text-lg font-bold mb-2 text-gray-700">{t('ClientWallet_amount')}</label>
                    <InputNumber id="amount" value={amount} onValueChange={(e) => setAmount(e.value)} className="w-full" />
                </div>
                <div className="mb-4">
                    <label htmlFor="paymentMethod" className="block text-lg font-bold mb-2 text-gray-700">{t('ClientWallet_payment_method')}</label>
                    <Dropdown 
                        id="paymentMethod" 
                        value={paymentMethod} 
                        options={paymentMethods} 
                        onChange={(e) => setPaymentMethod(e.value)} 
                        placeholder={t('ClientWallet_select_payment_method')} 
                        className="w-full" 
                        optionLabel="label" 
                        valueTemplate={(option) => (
                            option ? (
                                <div className="flex items-center">
                                    {option.icon}
                                    <span>{option.label}</span>
                                </div>
                            ) : <span>{t('ClientWallet_select_payment_method')}</span>
                        )} 
                        itemTemplate={(option) => (
                            <div className="flex items-center">
                                {option.icon}
                                <span>{option.label}</span>
                            </div>
                        )}
                    />
                </div>
                <Button label={t('ClientWallet_recharge')} icon="pi pi-check" onClick={rechargeWallet} className="w-full bg-gradient-to-r from-gray-700 to-gray-500 text-white px-4 py-2 rounded-lg" />
            </Dialog>
            <h3 className="text-2xl font-bold mb-4 text-center text-white">{t('ClientWallet_recharge_history')}</h3>
            {loadingTransactions ? (
                <div className="flex justify-center items-center h-64">
                    <ProgressSpinner />
                </div>
            ) : (
                <DataTable value={transactions} paginator rows={10} responsiveLayout="scroll" rowClassName={rowClassName} className="rounded-lg shadow-md overflow-hidden">
                    <Column field="amount" header={t('ClientWallet_amount')} body={amountTemplate} style={{ textAlign: 'right' }} />
                    <Column field="paymentMethod" header={t('ClientWallet_payment_method')} body={paymentMethodTemplate} style={{ textAlign: 'right' }} />
                    <Column field="timestamp" header={t('ClientWallet_date')} body={(rowData) => formatDate(rowData.timestamp)} style={{ textAlign: 'right' }} />
                    <Column field="description" header={t('ClientWallet_description')} body={descriptionTemplate} style={{ textAlign: 'right' }} />
                </DataTable>
            )}
        </div>
    );
};

export default ClientWallet;
