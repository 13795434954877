import React, { useState, useEffect } from 'react';
import CustomSlider from '../components/CustomSlider';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import postsImage from '../assets/images/rm.png';
import androidLogo from '../assets/images/android.png'; // تأكد من وجود الصورة في المسار الصحيح
import appleLogo from '../assets/images/apple.png'; // تأكد من وجود الصورة في المسار الصحيح



import kuwait from '../assets/images/kuwait.png'; // تأكد من وجود الصورة في المسار الصحيح
import saudi from '../assets/images/saudi-arabia.png'; // تأكد من وجود الصورة في المسار الصحيح
import united from '../assets/images/united-arab-emirates.png'; // تأكد من وجود الصورة في المسار الصحيح
import oman from '../assets/images/oman.png'; // تأكد من وجود الصورة في المسار الصحيح
import qatar from '../assets/images/qatar.png'; // تأكد من وجود الصورة في المسار الصحيح
import bahrin from '../assets/images/bahrain.png'; // تأكد من وجود الصورة في المسار الصحيح




import knet from '../assets/images/K_net.png'; // تأكد من وجود الصورة في المسار الصحيح
import paypal from '../assets/images/paypal.png'; // تأكد من وجود الصورة في المسار الصحيح
import amazon from '../assets/images/amazon-pay.png'; // تأكد من وجود الصورة في المسار الصحيح
import amex from '../assets/images/amex.png'; // تأكد من وجود الصورة في المسار الصحيح
import applepay from '../assets/images/apple-pay.png'; // تأكد من وجود الصورة في المسار الصحيح
import googlepay from '../assets/images/google-pay.png'; // تأكد من وجود الصورة في المسار الصحيح
import stc from '../assets/images/stcPay.png'; // تأكد من وجود الصورة في المسار الصحيح
import master from '../assets/images/master.png'; // تأكد من وجود الصورة في المسار الصحيح
import visa from '../assets/images/visa.png'; // تأكد من وجود الصورة في المسار الصحيح
import tabby from '../assets/images/tabby.png'; // تأكد من وجود الصورة في المسار الصحيح


const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    // تحديد التاريخ المستهدف ليكون 15 يناير 2025
    const targetDate = new Date('2025-01-16T00:00:00'); // تاريخ 15 يناير 2025 في منتصف الليل

    const interval = setInterval(() => {
        const now = new Date();
        const difference = targetDate - now;

        if (difference <= 0) {
            clearInterval(interval);
        } else {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            setTimeLeft({ days, hours, minutes, seconds });
        }
    }, 1000);

    return () => clearInterval(interval);
}, []);


  const handleMoClick = () => {
    navigate('/artists');
  };
  const handleHsClick = () => {
    navigate('/hairstulist_artist');
  };
  const handleMaClick = () => {
    navigate('/makeup_artist');
  };

  return (
    <div className="min-h-screen bg-cover bg-center relative" >
      <CustomSlider />

      <div className="relative z-10 flex flex-col items-center text-white text-center mt-20">
  <h2 className="text-4xl font-bold mb-4">{t('new_era_artists_creatives')}</h2>
  <h1 className="text-5xl font-bold mb-4">{t('app_name')}</h1>
  <p className="text-xl ">{t('coming_soon')}</p>
  <img src={postsImage} alt="Artesta Logo" className="h-[200px] sm:h-[400px] mx-auto mt-5 rounded-lg flex flex-col items-center transition-transform duration-300 hover:scale-105 cursor-pointer" />

  <div className="flex space-x-6 text-3xl mb-10 mt-5" style={{ direction: 'ltr' }}>
    <div>
      <span>{timeLeft.days}</span>
      <p className="text-sm">{t('countdown_days')}</p>
    </div>
    <div>
      <span>{timeLeft.hours}</span>
      <p className="text-sm">{t('countdown_hours')}</p>
    </div>
    <div>
      <span>{timeLeft.minutes}</span>
      <p className="text-sm">{t('countdown_minutes')}</p>
    </div>
    <div>
      <span>{timeLeft.seconds}</span>
      <p className="text-sm">{t('countdown_seconds')}</p>
    </div>
  </div>

  <div className="flex flex-wrap justify-center space-x-4 mb-10">
    <a href="#" className="bg-white text-black px-6 py-4 rounded-lg shadow-lg flex items-center justify-center space-x-3 transition-transform duration-300 hover:scale-105 cursor-pointer ml-3 mr-3">
      <img src={appleLogo} alt="Apple Store" className="h-[30px] w-auto ml-3" />
      <div className="flex flex-col items-center">
        <span className="text-sm text-gray-600">{t('soon_on')}</span>
        <span className="font-bold text-md">{t('apple_store')}</span>
      </div>
    </a>
    <a href="#" className="bg-white text-black px-6 py-4 rounded-lg shadow-lg flex items-center justify-center space-x-3 transition-transform duration-300 hover:scale-105 cursor-pointer mt-4 sm:mt-0 ml-3 mr-3">
      <img src={androidLogo} alt="Google Play" className="h-[30px] w-auto ml-3" />
      <div className="flex flex-col items-center">
        <span className="text-sm text-gray-600">{t('soon_on')}</span>
        <span className="font-bold text-md">{t('google_play')}</span>
      </div>
    </a>
  </div>

  <p className="text-xl mb-4">{t('start_from_kuwait')}</p>
  <div className="flex space-x-4 mb-10" style={{ direction: 'ltr' }}>
    <img src={kuwait} alt="kuwait" className="w-10 h-10" />
    <img src={saudi} alt="saudi" className="w-10 h-10" />
    <img src={united} alt="united" className="w-10 h-10" />
    <img src={bahrin} alt="bahrin" className="w-10 h-10" />
    <img src={qatar} alt="qatar" className="w-10 h-10" />
    <img src={oman} alt="oman" className="w-10 h-10" />
  </div>







  <div className="mt-12 text-center" style={{ direction: 'ltr' }}>
  <p className="text-lg text-white mb-4">{t('support_all_payment_methods')}</p>
  <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-7 gap-2 ml-1 ">
    <div className="col-span-1">
      <img src={knet} alt="Knet" className="w-[50px] h-[50px] object-contain" />
    </div>
    <div className="col-span-1">
      <img src={tabby} alt="tabby" className="w-[50px] h-[50px] object-contain" />
    </div>
    <div className="col-span-1">
      <img src={stc} alt="stc Pay" className="w-[50px] h-[50px] object-contain" />
    </div>
    <div className="col-span-1">
      <img src={visa} alt="Visa" className="w-[50px] h-[50px] object-contain" />
    </div>
    <div className="col-span-1">
      <img src={master} alt="Mastercard" className="w-[50px] h-[50px] object-contain" />
    </div>
    <div className="col-span-1">
      <img src={paypal} alt="PayPal" className="w-[50px] h-[50px] object-contain" />
    </div>
    <div className="col-span-1">
      <img src={amex} alt="American Express" className="w-[50px] h-[50px] object-contain" />
    </div>
    <div className="col-span-1">
      <img src={amazon} alt="Amazon Pay" className="w-[50px] h-[50px] object-contain" />
    </div>
    <div className="col-span-1">
      <img src={applepay} alt="Apple Pay" className="w-[50px] h-[50px] object-contain" />
    </div>
    <div className="col-span-1">
      <img src={googlepay} alt="Google Pay" className="w-[50px] h-[50px] object-contain" />
    </div>
  </div>
</div>


</div>


      {/* قسم المكونات السابقة */}

      <div className="container mx-auto custom-gridoo lg:gap-6 gap-2 md:gap-2 sm:gap-2 justify-center mt-7">
        <div className="bg-[#ece5df] rounded-lg shadow-md p-1 text-center transition-transform duration-300 hover:scale-105 cursor-pointer" onClick={handleMaClick}>
          <h3 className="text-black text-[11px] sm:text-lg font-bold mb-2">{t('makeup_artists')}</h3>
          <img src="/images/MAA.png" alt="Products" className="w-full sm:w-[450px] h-auto sm:h-auto object-cover rounded-lg" />
        </div>
        <div className="bg-[#ece5df] rounded-lg shadow-md p-1 text-center transition-transform duration-300 hover:scale-105 cursor-pointer" onClick={handleHsClick}>
          <h3 className="text-black text-[11px] sm:text-lg font-bold mb-2">{t('hair_stylists')}</h3>
          <img src="/images/HS.png" alt="Appointment" className="w-full sm:w-[450px] h-auto sm:h-auto object-cover rounded-lg" />
        </div>
        <div className="bg-[#ece5df] rounded-lg shadow-md p-1 text-center transition-transform duration-300 hover:scale-105 cursor-pointer" onClick={handleMoClick}>
          <h3 className="text-black text-[11px] sm:text-lg font-bold mb-2">{t('models')}</h3>
          <img src="/images/MODELS.png" alt="Posts" className="w-full sm:w-[450px] h-auto sm:h-auto object-cover rounded-lg" />
        </div>
      </div>
    </div>
  );
};

export default Home;
