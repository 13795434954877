import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import authService from '../services/authService';
import { useTranslation } from 'react-i18next';

const Cart = () => {
    const { t } = useTranslation();
    const [cartItems, setCartItems] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [checkoutDialogVisible, setCheckoutDialogVisible] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [wallet, setWallet] = useState(null);
    const toast = React.useRef(null);

    const paymentMethods = [
        { label: 'Visa', value: 'Visa' },
        { label: 'MasterCard', value: 'MasterCard' },
        { label: 'PayPal', value: 'PayPal' },
        { label: 'Wallet', value: 'Wallet' }
    ];

    useEffect(() => {
        const fetchCartItemsAndAddresses = async () => {
            try {
                const customerId = authService.getUserId();
                const token = localStorage.getItem('token');

                const cartResponse = await axios.get(`https://api.artistarsapp.com/api/carts/customer/${customerId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                const cartId = cartResponse.data.id;
                const itemsResponse = await axios.get(`https://api.artistarsapp.com/api/carts/${cartId}/items`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setCartItems(itemsResponse.data);

                const addressResponse = await axios.get(`https://api.artistarsapp.com/api/addresses/customer/${customerId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setAddresses(addressResponse.data);

                const walletResponse = await axios.get(`https://api.artistarsapp.com/api/client-wallets/customer/${customerId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setWallet(walletResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchCartItemsAndAddresses();
    }, []);

    const handleRemoveItem = (id) => {
        confirmDialog({
            message: t('Cart_confirm_remove'),
            header: t('Cart_confirm_remove_header'),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: t('yes'),
            rejectLabel: t('no'),
            acceptClassName: 'p-button-danger ml-2',
            rejectClassName: 'p-button-secondary',
            accept: () => removeItem(id),
            reject: () => {}
        });
    };

    const handleDecreaseQuantity = (id) => {
        const item = cartItems.find(item => item.id === id);
        if (item.quantity > 1) {
            decreaseQuantity(id);
        } else {
            confirmDialog({
                message: t('Cart_confirm_remove'),
                header: t('Cart_confirm_remove_header'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: t('yes'),
                rejectLabel: t('no'),
                acceptClassName: 'p-button-danger ml-2',
                rejectClassName: 'p-button-secondary',
                accept: () => removeItem(id),
                reject: () => {}
            });
        }
    };

    const removeItem = async (id) => {
        try {
            const customerId = authService.getUserId();
            const token = localStorage.getItem('token');
            const cartResponse = await axios.get(`https://api.artistarsapp.com/api/carts/customer/${customerId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const cartId = cartResponse.data.id;
            await axios.delete(`https://api.artistarsapp.com/api/carts/${cartId}/items/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setCartItems(cartItems.filter(item => item.id !== id));
        } catch (error) {
            console.error('Error removing cart item:', error);
        }
    };

    const increaseQuantity = async (id) => {
        const item = cartItems.find(item => item.id === id);
        try {
            const customerId = authService.getUserId();
            const token = localStorage.getItem('token');
            const cartResponse = await axios.get(`https://api.artistarsapp.com/api/carts/customer/${customerId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const cartId = cartResponse.data.id;
            await axios.post(`https://api.artistarsapp.com/api/carts/${cartId}/items/${id}/quantity`, { quantity: item.quantity + 1 }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setCartItems(cartItems.map(item =>
                item.id === id ? { ...item, quantity: item.quantity + 1 } : item
            ));
        } catch (error) {
            console.error('Error increasing cart item quantity:', error);
        }
    };

    const decreaseQuantity = async (id) => {
        const item = cartItems.find(item => item.id === id);
        try {
            const customerId = authService.getUserId();
            const token = localStorage.getItem('token');
            const cartResponse = await axios.get(`https://api.artistarsapp.com/api/carts/customer/${customerId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const cartId = cartResponse.data.id;
            await axios.post(`https://api.artistarsapp.com/api/carts/${cartId}/items/${id}/quantity`, { quantity: item.quantity - 1 }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setCartItems(cartItems.map(item =>
                item.id === id ? { ...item, quantity: item.quantity - 1 } : item
            ));
        } catch (error) {
            console.error('Error decreasing cart item quantity:', error);
        }
    };

    const handleCheckout = () => {
        const invalidItems = cartItems.filter(item => item.quantity > item.product.quantity);
        if (invalidItems.length > 0) {
            confirmDialog({
                message: t('Cart_warning'),
                header: t('Cart_warning'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: t('ok'),
                acceptClassName: 'p-button-warning ml-2',
                rejectClassName: 'p-button-secondary',
                rejectLabel: t('cancel'),
                accept: () => {}
            });
        } else {
            setCheckoutDialogVisible(true);
        }
    };

    const handleConfirmCheckout = async () => {
        try {
            const customerId = authService.getUserId();
            const token = localStorage.getItem('token');
            const cartResponse = await axios.get(`https://api.artistarsapp.com/api/carts/customer/${customerId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const cartId = cartResponse.data.id;
    
            if (paymentMethod === 'Wallet' && wallet.balance < totalPrice) {
                toast.current.show({ severity: 'warn', summary: t('Cart_warning'), detail: t('Cart_insufficient_balance'), life: 3000 });
                return;
            }
    
            await axios.post(`https://api.artistarsapp.com/api/orders/from-cart/${cartId}/${selectedAddress}`, {}, {
                headers: { 'Authorization': `Bearer ${token}` },
                params: { paymentMethod }
            });
            setCartItems([]);
            setCheckoutDialogVisible(false);
            toast.current.show({ severity: 'success', summary: t('Cart_success'), detail: t('Cart_order_successful'), life: 3000 });
            window.location.reload();
        } catch (error) {
            console.error('Error creating order:', error);
            toast.current.show({ severity: 'error', summary: t('Cart_error'), detail: t('Cart_order_error'), life: 3000 });
        }
    };
    
    const totalPrice = cartItems.reduce((total, item) => total + (item.product.price * item.quantity), 0);
    
    return (
        <div className="p-4">
            <Toast ref={toast} />
            <h2 className="text-2xl font-bold mb-4 text-center text-white">{t('Cart_cart')}</h2>
            {cartItems.length === 0 ? (
                <p className="text-center text-white">{t('Cart_empty_cart')}</p>
            ) : (
                <div>
                    <ul className="space-y-4">
                        {cartItems.map((item) => (
                            <li key={item.id} className="flex flex-col lg:flex-row justify-between items-center bg-[#ece5df] p-4 rounded-lg shadow-md">
                                <img src={`https://api.artistarsapp.com${item.product.imageUrls[0]}`} alt={item.product.name} className="w-20 h-20 object-cover rounded-lg mb-4 lg:mb-0 lg:ml-4" />
                                <div className="flex-1 lg:ml-4 text-center lg:text-right text-white">
                                    <h3 className="text-xl font-bold mb-2">{item.product.name}</h3>
                                    <p className="mb-2">{t('Cart_product_price', { price: item.product.price })}</p>
                                    <div className="flex items-center justify-center lg:justify-start mt-2">
                                        <button onClick={() => handleDecreaseQuantity(item.id)} className="bg-[#ece5df] text-white px-2 py-1 rounded-l-lg">-</button>
                                        <p className="px-4">{item.quantity}</p>
                                        <button onClick={() => increaseQuantity(item.id)} className="bg-[#ece5df] text-white px-2 py-1 rounded-r-lg">+</button>
                                    </div>
                                </div>
                                <div className="mt-4 lg:mt-0">
                                    <Button onClick={() => handleRemoveItem(item.id)} label={t('Cart_remove')} className="p-button-danger p-button-rounded text-white" />
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className="mt-4 text-center lg:text-right text-white">
                        <h3 className="text-xl font-bold mb-2">{t('Cart_total', { totalPrice })}</h3>
                        <button onClick={handleCheckout} className="flex items-center justify-center space-x-2 space-x-reverse bg-gradient-to-r from-gray-700 to-gray-500 px-4 py-2 rounded-full hover:opacity-90 transition-opacity duration-300">
                            {t('Cart_checkout')}
                        </button>
                    </div>
                </div>
            )}
            <Dialog header={t('Cart_checkout_details')} visible={checkoutDialogVisible}
            
            contentStyle={{ background: 'linear-gradient(to right, #b7b7a4, #a5a58d, #636060)' }}
            headerStyle={{ background: 'linear-gradient(to right, #b7b7a4, #a5a58d, #636060)', color: 'black' }}
            style={{ width: '50vw' }} modal onHide={() => setCheckoutDialogVisible(false)} className="rtl">
                <div>
                    <h3 className="text-xl font-bold mb-4 text-center">{t('Cart_products')}</h3>
                    <ul className="space-y-4">
                        {cartItems.map((item) => (
                            <li key={item.id} className="flex justify-between items-center bg-[#ece5df] rounded-xl shadow-lg text-black p-4 ">
                                <img src={`https://api.artistarsapp.com${item.product.imageUrls[0]}`} alt={item.product.name} className="w-20 h-20 object-cover rounded-lg mb-4 lg:mb-0 lg:ml-4" />
                                <div className="flex-1 ml-4 text-center lg:text-right">
                                    <h3 className="text-xl font-bold mb-2">{item.product.name}</h3>
                                    <p className="mb-2">{t('Cart_product_price', { price: item.product.price })}</p>
                                    <p className="mb-2">{t('Cart_quantity')}: {item.quantity}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <h3 className="text-xl font-bold mt-4 text-center">{t('Cart_select_address')}</h3>
                    <div className="mt-2 space-y-4">
                        {addresses.map((address) => (
                            <Card key={address.id} className="p-4  bg-[#ece5df] rounded-xl shadow-lg text-black">
                                <div className="flex items-center">
                                    <Checkbox
                                    className="ml-4 "
                                        inputId={address.id}
                                        name="address"
                                        value={address.id}
                                        onChange={() => setSelectedAddress(address.id)}
                                        checked={selectedAddress === address.id}
                                    />
                                    <div className="ml-4">
                                        <h4 className="text-lg font-bold">{address.addressName}</h4>
                                        <p>{address.province}, {address.district}, {address.street}, {address.buildingNumber}</p>
                                        <p>{address.contactNumber}</p>
                                        <p>{address.description}</p>
                                    </div>
                                </div>
                            </Card>
                        ))}
                    </div>
                    <h3 className="text-xl font-bold mt-4 text-center">{t('Cart_payment_method')}</h3>
                    <Dropdown value={paymentMethod} options={paymentMethods} onChange={(e) => setPaymentMethod(e.value)} placeholder={t('Cart_select_payment_method')} className="w-full bg-[#ece5df] rounded-xl shadow-lg text-black" />
                    {paymentMethod === 'Wallet' && wallet && (
                        <div className="text-center">
                            <p className="text-lg font-semibold text-gray-800">{t('Cart_current_wallet_balance', { balance: wallet.balance })}</p>
                            <p className="text-lg font-semibold text-gray-800">{t('Cart_total', { totalPrice })}</p>
                        </div>
                    )}
                    <div className="mt-4 text-center">
                        <Button label={t('Cart_confirm_purchase')} onClick={handleConfirmCheckout} className=" space-x-2 space-x-reverse bg-gradient-to-r from-gray-700 to-gray-500 text-white px-4 py-2 rounded-full hover:opacity-90 transition-opacity duration-300" disabled={!selectedAddress} />
                    </div>
                </div>
            </Dialog>
            <ConfirmDialog />
        </div>
    );
    };
    
    export default Cart;
    