import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Register = () => {
    const { t } = useTranslation();
    const [form, setForm] = useState({
        fullname: '',
        name: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        privacyPolicy: false,
    });

    const [otp, setOtp] = useState('');
    const [showPolicy, setShowPolicy] = useState(false);
    const [errors, setErrors] = useState({});
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const toast = React.useRef(null);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setForm({
            ...form,
            [name]: type === 'checkbox' ? checked : value,
        });
    };



    const validateForm = () => {
        let formErrors = {};
        if (!form.name) formErrors.name = t("Register_name_required");
        if (!form.fullname) formErrors.fullname = t("Register_name_required");
        if (!form.email) {
            formErrors.email = t("Register_email_required");
        } else if (!/\S+@\S+\.\S+/.test(form.email)) {
            formErrors.email = t("Register_email_invalid");
        }
        if (!form.phone) {
            formErrors.phone = t("Register_phone_required");
        } else if (!/^\+\d{1,3}\d{6,14}$/.test(`+${form.phone}`)) { // هنا تم تعديل الكود ليضيف علامة +
            formErrors.phone = t("Register_phone_invalid");
        }
        if (!form.password) formErrors.password = t("Register_password_required");
        if (!form.confirmPassword) formErrors.confirmPassword = t("Register_confirm_password_required");
        if (form.password && form.confirmPassword && form.password !== form.confirmPassword) {
            formErrors.confirmPassword = t("Register_password_mismatch");
        }
        if (!form.privacyPolicy) formErrors.privacyPolicy = t("Register_privacy_policy_required");
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const submitRegistration = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            try {
                await axios.post('https://api.artistarsapp.com/api/users/register/customer', form);
                setLoading(false);
                toast.current.show({ severity: 'success', summary: t("Register_success"), detail: t("Register_otp_sent"), life: 5000 });
                setStep(2);
            } catch (error) {
                setLoading(false);
                setErrors({ form: t("Register_error") });
                toast.current.show({ severity: 'error', summary: t("Register_error_summary"), detail: t("Register_error_detail"), life: 5000 });
            }
        }
    };

    const submitOTP = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await axios.post('https://api.artistarsapp.com/api/users/verify-otp', {
                email: form.email,
                otp: otp,
                username: form.name,
                password: form.password,
                fullname: form.fullname,

            });
            setLoading(false);
            toast.current.show({ severity: 'success', summary: t("Register_otp_success"), detail: t("Register_otp_verified"), life: 5000 });
            navigate('/login');
        } catch (error) {
            setLoading(false);
            setErrors({ form: t("Register_otp_error") });
            toast.current.show({ severity: 'error', summary: t("Register_otp_error_summary"), detail: t("Register_otp_error_detail"), life: 5000 });
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen ">
            <Toast ref={toast} />
            {loading && <ProgressBar mode="indeterminate" style={{ height: '6px' }} />}
            <div className="bg-[#ece5df] rounded-xl shadow-lg px-8 pt-6 pb-8 mb-4 flex flex-col w-full max-w-md">
                {step === 1 && (
                    <>
                        <h2 className="text-2xl font-bold mb-6 text-center">{t("Register_title")}</h2>
                        <form onSubmit={submitRegistration}>

                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                    {t("Register_full_name")}
                                </label>
                                <InputText id="fullname" name="fullname" type="text" placeholder={t("Register_full_name")} value={form.fullname} onChange={handleInputChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" />
                                {errors.fullname && <p className="text-red-500 text-xs italic">{errors.fullname}</p>}
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                    {t("Register_user_name")}
                                </label>
                                <InputText id="name" name="name" type="text" placeholder={t("Register_user_name")} value={form.name} onChange={handleInputChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" />
                                {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                    {t("Register_email")}
                                </label>
                                <InputText id="email" name="email" type="email" placeholder={t("Register_email")} value={form.email} onChange={handleInputChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" />
                                {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                                    {t("Register_phone")}
                                </label>
                                <PhoneInput
                                    country={'kw'}
                                    value={form.phone}
                                    onChange={phone => setForm({ ...form, phone })}
                                    inputClass="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                                    disableCountryCode={false}
                                    disableDropdown={false}
                                    inputStyle={{ direction: 'ltr', paddingLeft: '50px' }}
                                />
                                {errors.phone && <p className="text-red-500 text-xs italic">{errors.phone}</p>}
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                    {t("Register_password")}
                                </label>
                                <InputText id="password" name="password" type="password" placeholder={t("Register_password")} value={form.password} onChange={handleInputChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3" />
                                {errors.password && <p className="text-red-500 text-xs italic">{errors.password}</p>}
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
                                    {t("Register_confirm_password")}
                                </label>
                                <InputText id="confirmPassword" name="confirmPassword" type="password" placeholder={t("Register_confirm_password")} value={form.confirmPassword} onChange={handleInputChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3" />
                                {errors.confirmPassword && <p className="text-red-500 text-xs italic">{errors.confirmPassword}</p>}
                            </div>
                            <div className="mb-6">
                                <Checkbox inputId="policy" name="privacyPolicy" checked={form.privacyPolicy} onChange={handleInputChange} className="ml-3" />
                                <label htmlFor="policy" className="p-checkbox-label">{t("Register_agree_policy")} <a href="#" onClick={() => setShowPolicy(true)} className="text-black">{t("Register_policy")}</a>.</label>
                                {errors.privacyPolicy && <p className="text-red-500 text-xs italic">{errors.privacyPolicy}</p>}
                            </div>
                            <div className="flex items-center justify-between">
                                <Button label={t("Register_button")}  className="mt-4 bg-gradient-to-r from-gray-700 to-gray-500 text-white px-4 py-2 rounded-lg" type="submit" />
                            </div>
                            <div className="flex items-center justify-between mt-4">
                                <span className="text-gray-700 text-sm">
                                    {t("Register_already_have_account")} <a href="/login" className="text-blue-600 hover:underline">{t("Register_login_here")}</a>
                                </span>
                            </div>

                        </form>
                    </>
                )}
                {step === 2 && (
                    <>
                        <h2 className="text-2xl font-bold mb-6 text-center">{t("Register_otp_title")}</h2>
                        <form onSubmit={submitOTP}>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="otp">
                                    {t("Register_otp_placeholder")}
                                </label>
                                <InputText id="otp" name="otp" type="text" placeholder={t("Register_otp_placeholder")} value={otp} onChange={(e) => setOtp(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" />
                                {errors.otp && <p className="text-red-500 text-xs italic">{errors.otp}</p>}
                            </div>
                            <div className="flex items-center justify-between">
                                <Button label={t("Register_otp_button")}  className="mt-4 bg-gradient-to-r from-gray-700 to-gray-500 text-white px-4 py-2 rounded-lg" type="submit" />
                            </div>

                        </form>
                    </>
                )}
                <Dialog header={t("Register_policy_title")} visible={showPolicy} modal style={{ width: '50vw' }} onHide={() => setShowPolicy(false)}>
                    <p>{t("Register_policy_content")}</p>
                </Dialog>
            </div>
        </div>
    );
};

export default Register;
