import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import axios from 'axios';
import authService from '../services/authService'; // Assuming you have a service for auth
import { useTranslation } from 'react-i18next';

const UserAppointments = () => {
  const { t } = useTranslation();
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const token = authService.getToken();
  const customerId = authService.getUserId();

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const response = await axios.get(`https://api.artistarsapp.com/api/appointments/customer/${customerId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        // ترتيب المواعيد حسب الأحدث
        const sortedAppointments = response.data.sort((a, b) => b.id - a.id);
        setAppointments(sortedAppointments);
      } catch (error) {
        console.error('Error fetching appointments:', error);
      }
    };

    fetchAppointments();
  }, [customerId, token]);

  const renderAppointmentDetails = (appointment) => {
    switch (appointment.artist.type) {
      case 'MODEL':
        return (
          <>
            <p><strong>{t('moderate_makeup_package_count')}:</strong> {appointment.moderateMakeupPackageCount}</p>
            <p><strong>{t('full_makeup_package_count')}:</strong> {appointment.fullMakeupPackageCount}</p>
            <p><strong>{t('dress_package_count')}:</strong> {appointment.dressPackageCount}</p>
            <p><strong>{t('abaya_package_count')}:</strong> {appointment.abayaPackageCount}</p>
            <p><strong>{t('full_photo_session_count')}:</strong> {appointment.fullPhotoSessionCount}</p>
          </>
        );
      case 'HAIR_STYLIST':
        return (
          <>
            <p><strong>{t('moderate_hair_style_count')}:</strong> {appointment.moderateHairStyleCount}</p>
            <p><strong>{t('bride_hair_style_count')}:</strong> {appointment.brideHairStyleCount}</p>
            <p><strong>{t('special_hair_style_with_extensions_count')}:</strong> {appointment.specialHairStyleWithExtensionsCount}</p>
          </>
        );
      case 'MAKEUP_ARTIST':
        return (
          <>
            <p><strong>{t('bride_count')}:</strong> {appointment.brideCount}</p>
            <p><strong>{t('bridesmaid_count')}:</strong> {appointment.bridesmaidCount}</p>
            <p><strong>{t('moderate_makeup_count')}:</strong> {appointment.moderateMakeupCount}</p>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto p-6 text-right">
      <h2 className="text-3xl font-bold mb-6 text-center text-white">{t('UserAppointments_title')}</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {appointments.map((appointment) => (
          <div key={appointment.id} className=" bg-[#ece5df] text-black shadow-md rounded-lg p-4 relative">
            <h3 className="text-lg font-semibold mb-2">{appointment.artist.fullname}</h3>
            <p><strong>{t('UserAppointments_date')}:</strong> {appointment.date}</p>
            <p><strong>{t('UserAppointments_time')}:</strong> {appointment.time}</p>
            <p><strong>{t('UserAppointments_status')}:</strong> {appointment.status === 'Pending' ? t('UserAppointments_pending') : t('UserAppointments_confirmed')}</p>
            <p><strong>{t('UserAppointments_total_price')}:</strong> {appointment.totalPrice} KWD</p>
            <Button onClick={() => setSelectedAppointment(appointment)} label={t('UserAppointments_view_details')} className="flex items-center justify-center space-x-2 space-x-reverse bg-gradient-to-r from-gray-700 to-gray-500 px-4 py-2 rounded-full hover:opacity-90 transition-opacity duration-300 mt-2 text-white" />
          </div>
        ))}
      </div>
      <Dialog header={t('UserAppointments_details')} visible={selectedAppointment !== null} onHide={() => setSelectedAppointment(null)} 
        className="rtl"
        contentStyle={{ background: '#ece5df' }}
        headerStyle={{ background: '#ece5df', color: 'black' }}>
        {selectedAppointment && (
          <div className="p-4 ">
            <h3 className="text-xl font-bold mb-4 text-black">{t('UserAppointments_details')}</h3>
            <div className="space-y-2 text-black">
              <p><strong>{t('UserAppointments_artist_name')}:</strong> {selectedAppointment.artist.fullname}</p>
              <p><strong>{t('UserAppointments_appointment_date')}:</strong> {selectedAppointment.date}</p>
              <p><strong>{t('UserAppointments_appointment_time')}:</strong> {selectedAppointment.time}</p>
              <p><strong>{t('UserAppointments_status')}:</strong> {selectedAppointment.status === 'Pending' ? t('UserAppointments_pending') : t('UserAppointments_confirmed')}</p>
              <p><strong>{t('UserAppointments_total_price')}:</strong> {selectedAppointment.totalPrice} KWD</p>
              {renderAppointmentDetails(selectedAppointment)}
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default UserAppointments;
