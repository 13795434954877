import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const currentLanguage = i18n.language === 'en' ? 'ar' : 'en';

  return (
    <div className="flex align-items-center space-x-2 space-x-reverse mt-2">
      <Button 
        icon={`flag-icon ${i18n.language === 'en' ? 'flag-icon-kw' : 'flag-icon-gb'}`} 
        onClick={() => changeLanguage(currentLanguage)} 
        className="p-button-text"
        tooltip={i18n.language === 'en' ? '' : ''}
      />
    </div>
  );
};

export default LanguageSwitcher;
