import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import 'tailwindcss/tailwind.css';

const CustomSlider = () => {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const fetchHeaderBanners = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://api.artistarsapp.com/api/advertisement-banners');
        const headerBanners = response.data.filter(banner => banner.type === 'header');
        setSlides(headerBanners.map(banner => ({ id: banner.id, image: `https://api.artistarsapp.com${banner.imageUrl.replace("/home/ec2-user/uploads/", "/uploads/")}` })));
      } catch (error) {
        console.error('Error fetching header banners:', error);
      }
    };

    fetchHeaderBanners();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    adaptiveHeight: true
  };

  return (
    <div className="overflow-hidden">
      <Slider {...settings}>
        {slides.map(slide => (
          <div key={slide.id} className="w-full h-64 sm:h-80 md:h-96 lg:h-[75vh]">
            <img src={slide.image} alt={`Slide ${slide.id}`} className="w-full h-full max-w-full object-contain md:object-cover rounded-lg" />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default CustomSlider;
