import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Card } from 'primereact/card';
import { Avatar } from 'primereact/avatar';
import { useTranslation } from 'react-i18next';

const UserProfile = () => {
    const { t } = useTranslation();
    const [customer, setCustomer] = useState(null);
    const [email, setEmail] = useState('');
    const [fullname, setFullname] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [avatar, setAvatar] = useState(null);
    const toast = useRef(null);

    const customerId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                const response = await axios.get(`https://api.artistarsapp.com/api/users/customer/${customerId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setCustomer(response.data);
                setEmail(response.data.email);
                setFullname(response.data.fullname);
                setContactNumber(response.data.contactNumber);
                setAvatar(response.data.avatar); // Assuming avatar URL is returned from backend
            } catch (error) {
                console.error('Error fetching customer:', error);
            }
        };

        fetchCustomer();
    }, [customerId, token]);

    const handleUpdate = async () => {
        try {
            const formData = new FormData();
            formData.append('email', email);
            formData.append('fullname', fullname);
            formData.append('contactNumber', contactNumber);
            if (avatar instanceof File) {
                formData.append('avatar', avatar);
            }

            const response = await axios.put(`https://api.artistarsapp.com/api/users/update/customer/${customerId}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            setCustomer(response.data);
            toast.current.show({ severity: 'success', summary: t('UserProfile_success'), detail: t('UserProfile_success_detail'), life: 3000 });
        } catch (error) {
            console.error('Error updating customer profile:', error);
            toast.current.show({ severity: 'error', summary: t('UserProfile_error'), detail: t('UserProfile_error_detail'), life: 3000 });
        }
    };

    return (
        <div className="container mx-auto mt-8 flex justify-center items-center">
            <Toast ref={toast} />
            <Card className="p-4 w-full max-w-md bg-[#ece5df]">
                <h2 className="text-2xl font-bold mb-4 text-center text-black">{t('UserProfile_update_profile')}</h2>
                {customer && (
                    <div className="flex flex-col items-center space-y-4">
                        {avatar && typeof avatar === 'string' && (

                            
                            <Avatar image={`https://api.artistarsapp.com${avatar.replace("/home/ec2-user/uploads/", "/uploads/")}`} size="xlarge" style={{ width: '150px', height: '150px' }} shape="circle" />
                        )}
                        <div className="field w-full text-center text-black">
                            <label htmlFor="email">{t('UserProfile_email')}</label>
                            <InputText id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="w-full bg-[#d4d2d0] border text-black" />
                        </div>
                        <div className="field w-full text-center text-black">
                            <label htmlFor="fullname">{t('UserProfile_fullname')}</label>
                            <InputText id="fullname" value={fullname} onChange={(e) => setFullname(e.target.value)} className="w-full bg-[#d4d2d0] border text-black" />
                        </div>
                        <div className="field w-full text-center text-black">
                            <label htmlFor="contactNumber">{t('UserProfile_contact_number')}</label>
                            <InputText id="contactNumber" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} className="w-full bg-[#d4d2d0] border text-black" />
                        </div>
                        <div className="field w-full text-center text-black">
                            <label htmlFor="avatar">{t('UserProfile_avatar')}</label>
                            <FileUpload
                                id="avatar"
                                customUpload
                                auto
                                chooseLabel={t('UserProfile_choose_image')}
                                uploadHandler={(e) => setAvatar(e.files[0])}
                                className="custom-file-upload"
                                
                            />
                        </div>
                        <Button label={t('UserProfile_update')} icon="pi pi-check" onClick={handleUpdate} className="flex items-center justify-center space-x-2 space-x-reverse bg-gradient-to-r from-gray-700 to-gray-500 text-white px-4 py-2 rounded-full hover:opacity-90 transition-opacity duration-300" />
                    </div>
                )}
            </Card>
        </div>
    );
};

export default UserProfile;
