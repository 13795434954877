import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Dialog } from 'primereact/dialog';
import { Avatar } from 'primereact/avatar';
import authService from '../services/authService';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const PostsPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [artist, setArtist] = useState(null);
    const [posts, setPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState(null);
    const customerId = authService.getUserId(); // الحصول على معرف العميل
    const token = authService.getToken();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchArtist = async () => {
            try {
                const response = await axios.get(`https://api.artistarsapp.com/api/users/artist/${id}`);
                setArtist(response.data);
            } catch (error) {
                console.error('Error fetching artist data:', error);
            }
        };

        fetchArtist();
    }, [id]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const url = token
                    ? `https://api.artistarsapp.com/api/posts/artist/${id}?customerId=${customerId}`
                    : `https://api.artistarsapp.com/api/posts/artist/${id}/posts`;

                const headers = token
                    ? {}
                    : {};

                const response = await axios.get(url, { headers });
                const sortedPosts = response.data.sort((a, b) => b.id - a.id);
                setPosts(sortedPosts);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchPosts();
    }, [id, token, customerId]);

    const handlePostClick = (post) => {
        setSelectedPost(post);
    };

    const toggleLike = async (postId) => {
        try {
            await axios.post(`https://api.artistarsapp.com/api/posts/${postId}/like`, null, {
                params: { customerId: customerId },
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            // تحديث عدد الإعجابات وحالة الإعجاب
            setPosts(posts.map(post => {
                if (post.id === postId) {
                    return {
                        ...post,
                        likes: post.liked ? post.likes - 1 : post.likes + 1,
                        liked: !post.liked
                    };
                }
                return post;
            }));
            if (selectedPost && selectedPost.id === postId) {
                setSelectedPost(prevSelectedPost => ({
                    ...prevSelectedPost,
                    likes: prevSelectedPost.liked ? prevSelectedPost.likes - 1 : prevSelectedPost.likes + 1,
                    liked: !prevSelectedPost.liked
                }));
            }
        } catch (error) {
            console.error('Error toggling like:', error);
        }
    };

    const formatDate = (dateArray) => {
        const date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2], dateArray[3], dateArray[4], dateArray[5]);
        const now = new Date();
        const diffTime = Math.abs(now - date);
        const diffSeconds = Math.floor(diffTime / 1000);
        const diffMinutes = Math.floor(diffTime / (1000 * 60));
        const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        if (diffSeconds < 60) {
            return t('PostsPage_seconds_ago', { count: diffSeconds });
        } else if (diffMinutes < 60) {
            return t('PostsPage_minutes_ago', { count: diffMinutes });
        } else if (diffHours < 24) {
            return t('PostsPage_hours_ago', { count: diffHours });
        } else {
            return t('PostsPage_days_ago', { count: diffDays });
        }
    };

    const avatarUrl = artist && artist.avatar
        ? `https://api.artistarsapp.com${artist.avatar.replace("/home/ec2-user/uploads/", "/uploads/")}`
        : "https://e7.pngegg.com/pngimages/813/118/png-clipart-silhouette-icon-blank-person-template-share-icon-black-and-white.png";

    return (
        <div className="">
            {artist && (
                <div className="flex flex-col items-center bg-[#ece5df] p-8 rounded-lg shadow-lg text-white mb-8">
                    <img className="w-48 h-64 rounded-lg"
                        src={avatarUrl}
                        alt="Artist Avatar" />
                    <h2 className="text-xl text-center font-bold mt-4 text-black ">{artist.fullname}</h2>
                    <div className="flex flex-row items-center w-full mt-4 text-black justify-around mb-4">
                        <div className="text-center">
                            <p className="text-xl font-semibold">{artist.products ? artist.products.length : 0}</p>
                            <p className="text-sm font-semibold" onClick={() => {
                                navigate(`/categories/${id}`);
                            }} >{t('ArtistProfile_products')}</p>
                        </div>
                        <div className="text-center">
                            <p className="text-xl font-semibold">
                                {artist.fans ? artist.fans.length + (artist.fakeFansCount || 0) : (artist.fakeFansCount || 0)}
                            </p>
                            <p className="text-sm font-semibold"
                             onClick={() => {
                                navigate(`/artist/${artist.id}`);
                            }}>{t('ArtistProfile_followers')}</p>
                        </div>
                        <div className="text-center">
                            <p className="text-xl font-semibold">{posts ? posts.length : 0}</p>
                            <p className="text-sm font-semibold"
                             onClick={() => {
                                navigate(`/artist/${id}/posts`);
                            }}>{t('ArtistProfile_posts')}</p>
                        </div>
                    </div>
                </div>
            )}

            <div className="custom-grido lg:gap-6 gap-2 md:gap-2 sm:gap-2 justify-center">
                {posts.map((post, index) => (
                   <div key={index} className="relative hover:scale-105 cursor-pointer" onClick={() => handlePostClick(post)}>
                   {post.type === 'video' ? (
                       <>
                           {post.coverUrl ? (
                               <img src={`https://api.artistarsapp.com${post.coverUrl.replace("/home/ec2-user/uploads/", "/uploads/")}`} alt={`Post ${index}`} className="w-72 h-48 object-cover rounded-lg" style={{ maxHeight: '200px', objectFit: 'cover' }} />
                           ) : (
                               <video className="w-72 h-48 object-cover rounded-lg" style={{ maxHeight: '200px' }} loop muted>
                                   <source src={`https://api.artistarsapp.com${post.url.replace("/home/ec2-user/uploads/", "/uploads/")}`} type="video/mp4" />
                                   Your browser does not support the video tag.
                               </video>
                           )}
                           <i className="pi pi-video text-white absolute top-3 left-4 sm:ml-1 md:ml-2 lg:ml-5" style={{ fontSize: '1.5rem' }}></i>
                       </>
                   ) : (
                       <>
                           <img src={`https://api.artistarsapp.com${post.url.replace("/home/ec2-user/uploads/", "/uploads/")}`} alt={`Post ${index}`} className="w-72 h-48 object-cover rounded-lg" style={{ maxHeight: '200px', objectFit: 'cover' }} />
                           <i className="pi pi-image text-white absolute top-3 left-4 sm:ml-1 md:ml-2 lg:ml-5" style={{ fontSize: '1.5rem' }}></i>
                       </>
                   )}
               </div>
               
                ))}
            </div>


            <Dialog
                header={
                    <div className="flex flex-column items-start">
                        <div className="flex items-center">
                            <Avatar image={avatarUrl} className="mr-2 ml-2" shape="circle" />
                            <div className="flex flex-col">
                                <span className="font-bold text-sm">{artist ? artist.fullname : ''}</span>
                                <span className="text-gray-600 text-sm">
                                    {selectedPost ? formatDate(selectedPost.createdAt) : ''}
                                </span>
                            </div>
                        </div>
                        <div className="mt-4 mr-3">
                            <span className="text-sm font-bold">{selectedPost ? selectedPost.caption : ''}</span>
                        </div>
                    </div>
                }

                visible={!!selectedPost}
                style={{ width: '90vw', maxWidth: '800px' }}
                contentStyle={{ background: '#ece5df' }}
                headerStyle={{ background: '#ece5df', color: 'black' }}
                onHide={() => setSelectedPost(null)}
            >
                {selectedPost && (
                    <div>
                        {selectedPost.type === 'video' ? (
                            <video controls className="w-full h-auto rounded-lg mb-4" style={{ maxHeight: '500px' }}>
                                <source src={`https://api.artistarsapp.com${selectedPost.url.replace("/home/ec2-user/uploads/", "/uploads/")}`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <img src={`https://api.artistarsapp.com${selectedPost.url.replace("/home/ec2-user/uploads/", "/uploads/")}`} alt="Selected Post" className="w-full h-auto object-cover rounded-lg mb-4" style={{ maxHeight: '500px', objectFit: 'contain' }} />
                        )}
                        <div className="flex justify-between items-center mt-4 text-black">
                            <span>{selectedPost.likes} {t('PostsPage_likes')}</span>
                            {token && (
                                <button className={`flex items-center justify-center space-x-2 space-x-reverse bg-gradient-to-r from-gray-700 to-gray-500 text-white px-4 py-2 rounded-full hover:opacity-90 transition-opacity duration-300`} onClick={() => toggleLike(selectedPost.id)}>
                                    <i className={`pi pi-thumbs-up${selectedPost.liked ? '' : '-o'}`}></i> {selectedPost.liked ? t('PostsPage_unlike') : t('PostsPage_like')}
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </Dialog>
        </div>
    );
};

export default PostsPage;
