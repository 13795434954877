import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaCalendarAlt, FaShoppingCart, FaImages } from 'react-icons/fa';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import authService from '../services/authService';
import { useTranslation } from 'react-i18next';

const ArtistProfile = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [artistData, setArtistData] = useState(null);
    const [posts, setPosts] = useState([]);
    const customerId = authService.getUserId(); // الحصول على معرف العميل
    const { t } = useTranslation();

    useEffect(() => {
        const fetchArtistData = async () => {
            try {
                const response = await axios.get(`https://api.artistarsapp.com/api/users/artist/${id}`,);

                const filteredProducts = response.data.products.filter(product => !product.deleted);
                setArtistData({ ...response.data, products: filteredProducts });
            } catch (error) {
                console.error('Error fetching artist data:', error);
            }
        };

        fetchArtistData();
    }, [id]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {

                const response = await axios.get(`https://api.artistarsapp.com/api/posts/artist/${id}/posts`,);
                setPosts(response.data);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchPosts();
    }, [id, customerId]);

    const handleAppointmentClick = () => {
        navigate(`/artist/${id}/appointments`);
    };

    const handleProductsClick = () => {
        navigate(`/categories/${id}`);
    };

    const handlePostsClick = () => {
        navigate(`/artist/${id}/posts`);
    };

    const appointmentImage = "/images/appointment.png";
    const productImage = "/images/productpage.png";
    const postsImage = "/images/postpagee.png";

    return (
        <div >
            {artistData ? (
                <>
                    <div className="flex flex-col items-center bg-[#ece5df] p-1 rounded-lg shadow-lg text-white">
                        <img className="w-48 h-64 rounded-lg" src={artistData.avatar ? `https://api.artistarsapp.com${artistData.avatar.replace("/home/ec2-user/uploads/", "/uploads/")}` : 'https://e7.pngegg.com/pngimages/813/118/png-clipart-silhouette-icon-blank-person-template-share-icon-black-and-white.png'} alt={artistData.fullname} />
                        <h2 className="text-2xl font-bold mt-4 text-black text-center">{artistData.fullname}</h2>
                        <p className="text-center mt-2 text-black text-lg">{artistData.bio}</p>
                        <div className="flex flex-row items-center w-full mt-4 text-black justify-around mb-4">
                            <div className="text-center">
                                <p className="text-xl font-semibold">{artistData.products ? artistData.products.length : 0}</p>
                                <p className="text-sm font-semibold"
                                onClick={() => {
                                    navigate(`/categories/${id}`);
                                }}
                                >{t('ArtistProfile_products')}</p>
                            </div>
                            <div className="text-center">
                                <p className="text-xl font-semibold">
                                    {artistData.fans ? artistData.fans.length + (artistData.fakeFansCount || 0) : (artistData.fakeFansCount || 0)}
                                </p>
                                <p className="text-sm font-semibold">{t('ArtistProfile_followers')}</p>
                            </div>
                            <div className="text-center">
                                <p className="text-xl font-semibold">{posts ? posts.length : 0}</p>
                                <p className="text-sm font-semibold"
                                  onClick={() => {
                                    navigate(`/artist/${id}/posts`);
                                }}
                                >{t('ArtistProfile_posts')}</p>
                            </div>
                        </div>

                    </div>

                    <div className="container mx-auto custom-gridoo lg:gap-6 gap-2 md:gap-2 sm:gap-2 justify-center mt-7">
                        <div className="bg-[#ece5df] rounded-lg shadow-md p-2 text-center transition-transform duration-300 hover:scale-105 cursor-pointer" onClick={handleProductsClick}>
                            <h3 className="text-black text-[12px] sm:text-lg font-bold mb-2">{t('ArtistProfile_products')}</h3>
                            <img src={productImage} alt="Products" className="w-full h-[90px] sm:h-[200px] md:h-40 lg:h-[400px] object-fill rounded-lg" />
                        </div>
                        <div className="bg-[#ece5df] rounded-lg shadow-md p-2 text-center transition-transform duration-300 hover:scale-105 cursor-pointer" onClick={handleAppointmentClick}>
                            <h3 className="text-black text-[12px] sm:text-lg font-bold mb-2">{t('ArtistProfile_book_appointments')}</h3>
                            <img src={appointmentImage} alt="Appointment" className="w-full h-[90px] sm:h-[200px] md:h-40 lg:h-[400px] object-fill rounded-lg" />
                        </div>
                        <div className="bg-[#ece5df] rounded-lg shadow-md p-2 text-center transition-transform duration-300 hover:scale-105 cursor-pointer" onClick={handlePostsClick}>
                            <h3 className="text-black text-[12px] sm:text-lg font-bold mb-2">{t('ArtistProfile_posts')}</h3>
                            <img src={postsImage} alt="Posts" className="w-full h-[90px] sm:h-[200px] md:h-40 lg:h-[400px] object-fill rounded-lg" />
                        </div>
                    </div>









                </>
            ) : (
                <p>{t('ArtistProfile_loading_artist_data')}</p>
            )}
        </div>
    );
};

export default ArtistProfile;
