import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Card from '../components/Card';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { useTranslation } from 'react-i18next';

const HairStulistPage = () => {
    const { t } = useTranslation();
    const [artists, setArtists] = useState([]);

    useEffect(() => {
        const fetchArtists = async () => {
            try {
                const response = await axios.get('https://api.artistarsapp.com/api/users/artists', );
                const modelArtists = response.data.filter(artist => artist.approved && artist.type === 'HAIR_STYLIST');
                setArtists(modelArtists);
            } catch (error) {
                console.error('Error fetching artists:', error);
            }
        };

        fetchArtists();
    }, []);

    return (
        <div className="container mx-auto mt-8">
        <h2 className=" text-4xl font-bold mb-8 text-center text-white">{t('HairStulistPage_artists')}</h2>
        <div className="grid grid-cols-3 sm:grid-cols-3 gap-1">
            {artists.map(artist => (
                <Link key={artist.id} to={`/artist/${artist.id}`} className="block relative mx-auto">
                    <div className="transform transition-transform duration-300 hover:scale-105 cursor-pointer">
                        <Card
                            key={artist.id}
                            image={artist.avatar ? `https://api.artistarsapp.com${artist.avatar.replace("/home/ec2-user/uploads/", "/uploads/")}` : 'https://e7.pngegg.com/pngimages/813/118/png-clipart-silhouette-icon-blank-person-template-share-icon-black-and-white.png'}
                            title={artist.fullname}
                            artistId={artist.id}
                        />
                    </div>
                </Link>
            ))}
        </div>
    </div>
    );
};

export default HairStulistPage;
