import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import authService from '../services/authService';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const AddressForm = () => {
    const { t } = useTranslation();
    const [contactNumber, setContactNumber] = useState('');
    const [addressName, setAddressName] = useState('');
    const [description, setDescription] = useState('');
    const [province, setProvince] = useState('');
    const [district, setDistrict] = useState('');
    const [street, setStreet] = useState('');
    const [buildingNumber, setBuildingNumber] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [addresses, setAddresses] = useState([]);
    const [dialogVisible, setDialogVisible] = useState(false);

    const token = authService.getToken();
    const customerId = authService.getUserId();

    const provinces = ['محافظة العاصمة', 'محافظة حولي', 'محافظة الفروانية', 'محافظة مبارك الكبير', 'محافظة الأحمدي', 'محافظة الجهراء'];
    const districts = {
        'محافظة العاصمة': ['مدينة الكويت', 'بنيد القار', 'الدسمة', 'الدعية', 'الفيحاء', 'المنصورية', 'عبدالله السالم', 'النزهة', 'الروضة', 'العديلية', 'الخالدية', 'القادسية', 'الشامية', 'الشويخ', 'الصليبخات', 'غرناطة'],
        'محافظة حولي': ['حولي', 'السالمية', 'الرميثية', 'الجابرية', 'بيان', 'مشرف', 'ميدان حولي', 'الشعب', 'أنجفة', 'البدع', 'سلوى', 'حطين', 'الصديق', 'جنوب السرة'],
        'محافظة الفروانية': ['الفروانية', 'خيطان', 'الرابية', 'الري', 'العارضية', 'صباح الناصر', 'الفردوس', 'جليب الشيوخ', 'العمرية', 'الرقعي', 'الضجيج', 'الأندلس', 'الرابية', 'أبرق خيطان'],
        'محافظة مبارك الكبير': ['مبارك الكبير', 'العدان', 'القُرَين', 'القصور', 'صباح السالم', 'أبو فطيرة', 'أبو الحصانية', 'الفنيطيس', 'المسيلة', 'الوسطى'],
        'محافظة الأحمدي': ['الأحمدي', 'الفحيحيل', 'المنقف', 'أبو حليفة', 'الرقة', 'العقيلة', 'الفنطاس', 'المهبولة', 'مدينة صباح الأحمد البحرية', 'الزور', 'النويصيب', 'الخيران', 'بنيدر', 'جابر العلي', 'هدية'],
        'محافظة الجهراء': ['الجهراء', 'سعد العبدالله', 'القصر', 'العيون', 'النسيم', 'الواحة', 'النهضة', 'المطلاع', 'العبدلي', 'السالمي', 'الصبية', 'كبد', 'أم العيش']
    };

    const streets = {
        'مدينة الكويت': ['شارع الخليج', 'شارع عبد الله المبارك', 'شارع فهد السالم'],
        'بنيد القار': ['شارع الاستقلال', 'شارع البحرين'],
    };

    useEffect(() => {
        const fetchAddresses = async () => {
            try {
                const response = await axios.get(`https://api.artistarsapp.com/api/addresses/customer/${customerId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setAddresses(response.data);
            } catch (error) {
                console.error('Error fetching addresses:', error);
            }
        };

        fetchAddresses();
    }, [customerId, token]);

    const handleProvinceChange = (e) => {
        setProvince(e.target.value);
        setDistrict('');
        setStreet('');
    };

    const handleDistrictChange = (e) => {
        setDistrict(e.target.value);
        setStreet('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newAddress = {
            addressName,
            street,
            city: district,
            state: province,
            postalCode,
            country: 'Kuwait',
            phoneNumber: contactNumber,
            description,
            buildingNumber
        };

        try {
            const response = await axios.post(`https://api.artistarsapp.com/api/addresses/${customerId}`, newAddress, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setAddresses([...addresses, response.data]);
        } catch (error) {
            console.error('Error adding address:', error);
        }

        setContactNumber('');
        setAddressName('');
        setDescription('');
        setProvince('');
        setDistrict('');
        setStreet('');
        setBuildingNumber('');
        setPostalCode('');
        setDialogVisible(false);
    };

    const handleDelete = async (addressId) => {
        try {
            await axios.delete(`https://api.artistarsapp.com/api/addresses/${addressId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setAddresses(addresses.filter(address => address.id !== addressId));
        } catch (error) {
            console.error('Error deleting address:', error);
        }
    };

    return (
        <div className='rtl'>
            <div className="container mx-auto p-4">
                <h2 className="text-3xl font-bold mb-6 text-center text-white">{t('AddressForm_title')}</h2>
                <div className="flex justify-end mb-4">
                    <Button label={t('AddressForm_add_new')} icon="pi pi-plus" className="flex items-center justify-center space-x-2 space-x-reverse bg-gradient-to-r from-gray-700 to-gray-500 px-4 py-2 rounded-full hover:opacity-90 transition-opacity duration-300 text-white" onClick={() => setDialogVisible(true)} />
                </div>
                <Dialog visible={dialogVisible} header={t('AddressForm_add_new')} 
                
                contentStyle={{ background: '#ece5df' }}
                headerStyle={{ background: '#ece5df', color: 'black' }}
                modal onHide={() => setDialogVisible(false)} className="p-fluid rounded-lg">
                    <form className="space-y-4 text-right" onSubmit={handleSubmit}>
                        <div>
                            <label className="block text-gray-700">{t('AddressForm_address_name')}</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border rounded-md"
                                value={addressName}
                                onChange={(e) => setAddressName(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">{t('AddressForm_contact_number')}</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border rounded-md"
                                value={contactNumber}
                                onChange={(e) => setContactNumber(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">{t('AddressForm_description')}</label>
                            <textarea
                                className="w-full px-3 py-2 border rounded-md"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">{t('AddressForm_province')}</label>
                            <select
                                className="w-full px-3 py-2 border rounded-md"
                                value={province}
                                onChange={handleProvinceChange}
                                required
                            >
                                <option value="">{t('AddressForm_select_province')}</option>
                                {provinces.map((prov) => (
                                    <option key={prov} value={prov}>
                                        {prov}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {province && (
                            <div>
                                <label className="block text-gray-700">{t('AddressForm_district')}</label>
                                <select
                                    className="w-full px-3 py-2 border rounded-md"
                                    value={district}
                                    onChange={handleDistrictChange}
                                    required
                                >
                                    <option value="">{t('AddressForm_select_district')}</option>
                                    {districts[province].map((dist) => (
                                        <option key={dist} value={dist}>
                                            {dist}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {district && (
                            <div>
                                <label className="block text-gray-700">{t('AddressForm_street')}</label>
                                <select
                                    className="w-full px-3 py-2 border rounded-md"
                                    value={street}
                                    onChange={(e) => setStreet(e.target.value)}
                                    required
                                >
                                    <option value="">{t('AddressForm_select_street')}</option>
                                    {streets[district]?.map((str) => (
                                        <option key={str} value={str}>
                                            {str}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div>
                            <label className="block text-gray-700">{t('AddressForm_building_number')}</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border rounded-md"
                                value={buildingNumber}
                                onChange={(e) => setBuildingNumber(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">{t('AddressForm_postal_code')}</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border rounded-md"
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                                required
                            />
                        </div>
                        <Button type="submit" label={t('AddressForm_add_address')} className="flex items-center justify-center space-x-2 space-x-reverse bg-gradient-to-r from-gray-700 to-gray-500 px-4 py-2 rounded-full hover:opacity-90 transition-opacity duration-300 text-white" />
                    </form>
                </Dialog>
                <div className="mt-8">
                    <h3 className="text-2xl font-bold mb-4 text-white">{t('AddressForm_my_addresses')}</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ">
                        {addresses.map((address) => (
                            <div key={address.id} className="p-4 border rounded-md shadow-md bg-[#ece5df] text-black transition-transform transform hover:scale-105 duration-300">
                                <p><strong>{t('AddressForm_address_name_label')}:</strong> {address.addressName}</p>
                                <p><strong>{t('AddressForm_contact_number_label')}:</strong> {address.phoneNumber}</p>
                                <p><strong>{t('AddressForm_description_label')}:</strong> {address.description}</p>
                                <p><strong>{t('AddressForm_province_label')}:</strong> {address.state}</p>
                                <p><strong>{t('AddressForm_district_label')}:</strong> {address.city}</p>
                                <p><strong>{t('AddressForm_street_label')}:</strong> {address.street}</p>
                                <p><strong>{t('AddressForm_building_number_label')}:</strong> {address.buildingNumber}</p>
                                <p><strong>{t('AddressForm_postal_code_label')}:</strong> {address.postalCode}</p>
                                <Button
                                    label={t('AddressForm_delete')}
                                    icon="pi pi-trash"
                                    className="flex items-center justify-center space-x-2 space-x-reverse bg-gradient-to-r from-gray-700 to-gray-500 px-4 py-2 rounded-full hover:opacity-90 transition-opacity duration-300 text-white mt-2"
                                    onClick={() => handleDelete(address.id)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddressForm;