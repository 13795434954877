import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import { Galleria } from 'primereact/galleria';
import authService from '../services/authService';

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [layout, setLayout] = useState('grid');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('https://api.artistarsapp.com/api/products', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const getSeverity = (product) => {
        switch (product.quantity) {
            case 0:
                return 'danger';
            case 1:
            case 2:
                return 'warning';
            default:
                return 'success';
        }
    };

    const openProductDialog = (product) => {
        setSelectedProduct(product);
        setProductDialog(true);
    };

    const handleAddToCart = async (product) => {
        try {
            const token = localStorage.getItem('token');
            const customerId = authService.getUserId();
            const response = await axios.get(`https://api.artistarsapp.com/api/carts/customer/${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            
            const cart = response.data;
            const cartItemsResponse = await axios.get(`https://api.artistarsapp.com/api/carts/${cart.id}/items`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            
            const cartItems = cartItemsResponse.data;

            if (cartItems.length > 0) {
                const artistIdInCart = cartItems[0].product.artist.id;
                if (artistIdInCart !== product.artist.id) {
                    toast.current.show({ severity: 'warn', summary: 'تحذير', detail: 'لا يمكن إضافة منتجات من فنان آخر إلى السلة.', life: 3000 });
                    return;
                }
            }

            const newCartItem = { product: { id: product.id }, quantity: 1 };
            await axios.post(`https://api.artistarsapp.com/api/carts/${cart.id}/items`, newCartItem, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            toast.current.show({ severity: 'success', summary: 'نجاح', detail: 'تمت إضافة العنصر إلى السلة بنجاح.', life: 3000 });
        } catch (error) {
            console.error('Error adding to cart:', error);
            toast.current.show({ severity: 'error', summary: 'خطأ', detail: 'حدث خطأ أثناء إضافة العنصر إلى السلة.', life: 3000 });
        }
    };

    const itemTemplate = (item) => {
        return <img src={`https://api.artistarsapp.com${item}`} alt="Product Image" style={{ width: '100%', height: '300px', objectFit: 'contain' }} />;
    };

    const listItem = (product, index) => {
        return (
            <div className="col-12" key={product.id}>
                <div
                    className={classNames('flex flex-col xl:flex-row xl:items-start p-4 gap-4 border rounded-lg shadow-md bg-white transition-transform transform hover:scale-105 duration-300', { 'border-t-1 surface-border': index !== 0 })}
                >
                    <div className="w-full xl:w-1/3">
                        <Galleria value={product.imageUrls} numVisible={1} circular style={{ maxWidth: '640px' }}
                            showThumbnails={false} showItemNavigators item={itemTemplate} />
                    </div>
                    <div className="flex flex-col sm:flex-row justify-between items-center xl:items-start flex-1 gap-4">
                        <div className="flex flex-col items-center sm:items-start gap-3">
                            <div className="text-2xl font-bold text-gray-800 cursor-pointer" onClick={() => openProductDialog(product)}>{product.name}</div>
                            <Rating value={product.rating} readOnly cancel={false}></Rating>
                            <div className="flex items-center gap-3">
                                <Tag value={product.quantity > 0 ? 'متوفر' : 'غير متوفر'} severity={getSeverity(product)}></Tag>
                            </div>
                        </div>
                        <div className="flex sm:flex-col items-center sm:items-end gap-3 sm:gap-2">
                            <span className="text-2xl font-semibold text-gray-800">KWD {product.price}</span>
                            <Button icon="pi pi-shopping-cart" className="p-button-rounded bg-gradient-to-r from-gray-700 to-black text-white hover:opacity-90 transition-opacity duration-300" disabled={product.quantity === 0} onClick={() => handleAddToCart(product)}></Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const gridItem = (product) => {
        return (
            <div className="col-12 sm:col-6 lg:col-3 p-2" key={product.id}>
                <div
                    className="p-4 border rounded-lg shadow-md bg-white transition-transform transform hover:scale-105 duration-300"
                >
                    <div className="mb-3">
                        <Galleria value={product.imageUrls} numVisible={1} circular style={{ maxWidth: '640px' }}
                            showThumbnails={false} showItemNavigators item={itemTemplate} />
                    </div>
                    <div className="flex flex-col items-center gap-3 py-5">
                        <div className="text-2xl font-bold text-gray-800 cursor-pointer" onClick={() => openProductDialog(product)}>{product.name}</div>
                        <Rating value={product.rating} readOnly cancel={false}></Rating>
                    </div>
                    <div className="flex items-center justify-between mt-3">
                        <span className="text-2xl font-semibold text-gray-800">KWD {product.price}</span>
                        <Button icon="pi pi-shopping-cart" className="p-button-rounded bg-gradient-to-r from-gray-700 to-black text-white hover:opacity-90 transition-opacity duration-300" disabled={product.quantity === 0} onClick={() => handleAddToCart(product)}></Button>
                    </div>
                </div>
            </div>
        );
    };

    const itemTemplateFunction = (product, layout, index) => {
        if (!product) {
            return;
        }

        if (layout === 'list') return listItem(product, index);
        else if (layout === 'grid') return gridItem(product);
    };

    const header = () => {
        return (
            <div className="flex justify-end mb-4">
                <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
            </div>
        );
    };

    return (
        <div className="p-4">
            <Toast ref={toast} />
            <DataView value={products} itemTemplate={itemTemplateFunction} layout={layout} header={header()} />
            <Dialog
                visible={productDialog}
                style={{ width: '50vw' }}
                header={selectedProduct?.name}
                modal
                className="p-fluid"
                onHide={() => setProductDialog(false)}
            >
                {selectedProduct && (
                    <div className="grid">
                        <div className="col-12 md:col-6">
                            <Galleria value={selectedProduct.imageUrls} numVisible={1} circular style={{ maxWidth: '640px' }}
                                showThumbnails={false} showItemNavigators item={itemTemplate} />
                        </div>
                        <div className="col-12 md:col-6">
                            <h2 className="text-2xl font-bold mb-2 text-gray-800">{selectedProduct.name}</h2>
                            <Rating value={selectedProduct.rating} readOnly cancel={false}></Rating>
                            <p className="mt-2 text-gray-800">السعر: KWD {selectedProduct.price}</p>
                            <p className="mt-2 text-gray-800">الحالة: <Tag value={selectedProduct.quantity > 0 ? 'متوفر' : 'غير متوفر'} severity={getSeverity(selectedProduct)}></Tag></p>
                            <Button 
                                label="إضافة إلى السلة" 
                                icon="pi pi-shopping-cart" 
                                className="p-button-rounded bg-gradient-to-r from-gray-700 to-black text-white hover:opacity-90 transition-opacity duration-300 mt-4" 
                                disabled={selectedProduct.quantity === 0} 
                                onClick={() => handleAddToCart(selectedProduct)}
                            />
                        </div>
                    </div>
                )}
            </Dialog>
        </div>
    );
};

export default ProductList;
