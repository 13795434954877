import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import { useNavigate, useParams } from 'react-router-dom';

const Categories = () => {
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams(); // الحصول على معرف الفنان

    useEffect(() => {
        loadCategories();
    }, []);

    const loadCategories = async () => {
        const response = await axios.get(`https://api.artistarsapp.com/api/categories/artist/${id}`, );
        setCategories(response.data);
    };

    const handleCategoryClick = (categoryId) => {
        navigate(`/artist/${id}/products?category=${categoryId}`);
    };

    return (
        <div className="container mx-auto mt-10">
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={{ clickable: true }}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
            >
                {categories.map((category) => (
                    <SwiperSlide style={{ width: '350px', height: '350px' }} key={category.id} onClick={() => handleCategoryClick(category.id)}>
                        <div className="flex flex-col items-center text-center p-4 bg-[#ece5df] text-black rounded-lg shadow-md transition-transform transform hover:scale-105 duration-300">
                            <img 
                                src={category.imagePath ? `https://api.artistarsapp.com${category.imagePath.replace("/home/ec2-user/uploads/", "/uploads/")}` : ''}
                                alt={category.name}
                                style={{ width: '200px', height: '200px', objectFit: 'cover', borderRadius: '8px' }}
                            />
                            <div className="mt-4">
                                <h3 className="text-xl font-bold">{category.name}</h3>
                                <p className="text-gray-900">{category.description}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <style>
                {`
                .swiper-pagination-bullet {
                    background-color: rgba(255, 255, 255, 1);
                }
                .swiper-pagination-bullet-active {
                    background-color: #b7b7a4;
                }
                `}
            </style>
        </div>
    );
};

export default Categories;
