import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import authService from '../services/authService';

const ArtistAppointments = () => {
    const navigate = useNavigate();

    const { t } = useTranslation();
    const { id } = useParams();
    const [date, setDate] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState('');
    const [brideCount, setBrideCount] = useState(0);
    const [bridesmaidCount, setBridesmaidCount] = useState(0);
    const [moderateMakeupPackageCount, setModerateMakeupPackageCount] = useState(0);
    const [fullMakeupPackageCount, setFullMakeupPackageCount] = useState(0);
    const [dressPackageCount, setDressPackageCount] = useState(0);
    const [abayaPackageCount, setAbayaPackageCount] = useState(0);
    const [fullPhotoSessionCount, setFullPhotoSessionCount] = useState(0);
    const [moderateHairStyleCount, setModerateHairStyleCount] = useState(0);
    const [brideHairStyleCount, setBrideHairStyleCount] = useState(0);
    const [specialHairStyleWithExtensionsCount, setSpecialHairStyleWithExtensionsCount] = useState(0);
    const [moderateMakeupCount, setModerateMakeupCount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [availability, setAvailability] = useState({ booked: [], available: [] });
    const [workingHoursPricing, setWorkingHoursPricing] = useState(null);
    const [artistType, setArtistType] = useState(null);
    const [wallet, setWallet] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const toast = useRef(null);
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const isUserLoggedIn = authService.getToken() !== null; // تحقق مما إذا كان المستخدم مسجلاً دخول

    const paymentMethods = [
        { label: 'Visa', value: 'Visa' },
        { label: 'MasterCard', value: 'MasterCard' },
        { label: 'PayPal', value: 'PayPal' },
        { label: 'Wallet', value: 'Wallet' }
    ];

    useEffect(() => {
        const fetchWorkingHoursPricing = async () => {
            try {
                const response = await axios.get(`https://api.artistarsapp.com/api/working-hours-pricing/${id}`,);
                setWorkingHoursPricing(response.data);
                setArtistType(response.data.artist.type); // احصل على نوع الفنان
            } catch (error) {
                console.error('Error fetching working hours pricing:', error);
            }
        };

        const fetchWallet = async () => {
            try {
                const response = await axios.get(`https://api.artistarsapp.com/api/client-wallets/customer/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setWallet(response.data);
            } catch (error) {
                console.error('Error fetching wallet:', error);
            }
        };

        fetchWorkingHoursPricing();
        fetchWallet();
    }, [id, token, userId]);

    useEffect(() => {
        if (selectedDate) {
            const fetchAvailability = async () => {
                try {
                    const response = await axios.get(`https://api.artistarsapp.com/api/appointments/artist/${id}/${selectedDate}`,);
                    setAvailability(response.data);
                } catch (error) {
                    console.error('Error fetching availability:', error);
                }
            };

            fetchAvailability();
        }
    }, [id, selectedDate, token]);

    const formatDate = (date) => {
        if (!(date instanceof Date)) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const dayClassName = (date) => {
        if (!(date instanceof Date)) return '';
        const dateString = formatDate(date);
        const dayAvailability = availability[dateString];
        const dayOfWeek = date.getDay();


        const today = new Date();
        today.setHours(0, 0, 0, 0);


        if (workingHoursPricing?.daysOff?.includes(dayOfWeek)) {
            return 'bg-black text-white cursor-not-allowed';
        }

        if (date < today) {
            return 'bg-gray-200 text-gray-700 cursor-not-allowed day-past';
        }

        if (dayAvailability) {
            if (dayAvailability.booked.length > 0 && dayAvailability.available.length === 0) {
                return 'bg-red-200 text-red-700';
            } else if (dayAvailability.booked.length > 0) {
                return 'bg-yellow-200 text-yellow-700';
            }
        }

        if (selectedDate && date.toDateString() === new Date(selectedDate).toDateString()) {
            return 'bg-yellow-200 text-yellow-700';
        }

        return 'bg-green-200 text-green-700';
    };

    const handleDateChange = (e) => {
        const selected = e.value;
        const dayOfWeek = selected.getDay();
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (workingHoursPricing?.daysOff?.includes(dayOfWeek) || selected < today) {
            return;
        }

        setDate(selected);
        setSelectedDate(formatDate(selected));
        setSelectedTime('');
    };

    const handleCountChange = (type, value) => {
        let newBrideCount = brideCount;
        let newBridesmaidCount = bridesmaidCount;
        let newModerateMakeupPackageCount = moderateMakeupPackageCount;
        let newFullMakeupPackageCount = fullMakeupPackageCount;
        let newDressPackageCount = dressPackageCount;
        let newAbayaPackageCount = abayaPackageCount;
        let newFullPhotoSessionCount = fullPhotoSessionCount;
        let newModerateHairStyleCount = moderateHairStyleCount;
        let newBrideHairStyleCount = brideHairStyleCount;
        let newSpecialHairStyleWithExtensionsCount = specialHairStyleWithExtensionsCount;
        let newModerateMakeupCount = moderateMakeupCount;

        switch (type) {
            case 'bride':
                newBrideCount = value;
                setBrideCount(value);
                break;
            case 'bridesmaid':
                newBridesmaidCount = value;
                setBridesmaidCount(value);
                break;
            case 'moderateMakeupPackage':
                newModerateMakeupPackageCount = value;
                setModerateMakeupPackageCount(value);
                break;
            case 'fullMakeupPackage':
                newFullMakeupPackageCount = value;
                setFullMakeupPackageCount(value);
                break;
            case 'dressPackage':
                newDressPackageCount = value;
                setDressPackageCount(value);
                break;
            case 'abayaPackage':
                newAbayaPackageCount = value;
                setAbayaPackageCount(value);
                break;
            case 'fullPhotoSession':
                newFullPhotoSessionCount = value;
                setFullPhotoSessionCount(value);
                break;
            case 'moderateHairStyle':
                newModerateHairStyleCount = value;
                setModerateHairStyleCount(value);
                break;
            case 'brideHairStyle':
                newBrideHairStyleCount = value;
                setBrideHairStyleCount(value);
                break;
            case 'specialHairStyleWithExtensions':
                newSpecialHairStyleWithExtensionsCount = value;
                setSpecialHairStyleWithExtensionsCount(value);
                break;
            case 'moderateMakeup':
                newModerateMakeupCount = value;
                setModerateMakeupCount(value);
                break;
            default:
                break;
        }

        const bridePrice = workingHoursPricing?.bridePrice || 0;
        const bridesmaidPrice = workingHoursPricing?.bridesmaidPrice || 0;
        const moderateMakeupPackagePrice = workingHoursPricing?.moderateMakeupPackagePrice || 0;
        const fullMakeupPackagePrice = workingHoursPricing?.fullMakeupPackagePrice || 0;
        const dressPackagePrice = workingHoursPricing?.dressPackagePrice || 0;
        const abayaPackagePrice = workingHoursPricing?.abayaPackagePrice || 0;
        const fullPhotoSessionPrice = workingHoursPricing?.fullPhotoSessionPrice || 0;
        const moderateHairStylePrice = workingHoursPricing?.moderateHairStylePrice || 0;
        const brideHairStylePrice = workingHoursPricing?.brideHairStylePrice || 0;
        const specialHairStyleWithExtensionsPrice = workingHoursPricing?.specialHairStyleWithExtensionsPrice || 0;
        const moderateMakeupPrice = workingHoursPricing?.moderateMakeupPrice || 0;

        const newTotalPrice = (newBrideCount * bridePrice)
            + (newBridesmaidCount * bridesmaidPrice)
            + (newModerateMakeupPackageCount * moderateMakeupPackagePrice)
            + (newFullMakeupPackageCount * fullMakeupPackagePrice)
            + (newDressPackageCount * dressPackagePrice)
            + (newAbayaPackageCount * abayaPackagePrice)
            + (newFullPhotoSessionCount * fullPhotoSessionPrice)
            + (newModerateHairStyleCount * moderateHairStylePrice)
            + (newBrideHairStyleCount * brideHairStylePrice)
            + (newSpecialHairStyleWithExtensionsCount * specialHairStyleWithExtensionsPrice)
            + (newModerateMakeupCount * moderateMakeupPrice);

        setTotalPrice(newTotalPrice);
    };






    const handleTimeSelect = (time) => {
        setSelectedTime(time);
    };

    const handleBooking = async () => {
        setShowDialog(true);
    };

    const confirmBooking = async () => {
        if (paymentMethod === 'Wallet' && wallet.balance < totalPrice) {
            toast.current.show({ severity: 'warn', summary: t('insufficient_balance'), detail: t('insufficient_wallet_balance'), life: 3000 });
            return;
        }

        try {
            const response = await axios.post('https://api.artistarsapp.com/api/appointments', {
                artist: { id },
                date: selectedDate,
                time: selectedTime,
                brideCount,
                bridesmaidCount,
                moderateMakeupPackageCount,
                fullMakeupPackageCount,
                dressPackageCount,
                abayaPackageCount,
                fullPhotoSessionCount,
                moderateHairStyleCount,
                brideHairStyleCount,
                specialHairStyleWithExtensionsCount,
                moderateMakeupCount,
                status: 'Pending',
                paymentMethod,
                totalPrice
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    paymentMethod,
                    amount: totalPrice
                }
            });

            if (response.status === 201) {
                toast.current.show({ severity: 'success', summary: t('success'), detail: t('appointment_booked_successfully'), life: 3000 });
                setShowDialog(false);
                window.location.reload();
            }
        } catch (error) {
            console.error('Error booking appointment:', error);
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('error_booking_appointment'), life: 3000 });
        }
    };

    const selectedAvailability = availability || {
        booked: [],
        available: workingHoursPricing?.workingHours || []
    };

    const renderServiceInputs = () => {
        if (!artistType) return null;

        switch (artistType) {
            case 'MODEL':
                return (
                    <>
                        <div className="flex items-center justify-between">
                            <label className="text-sm font-semibold text-gray-800">{t('moderate_makeup_package_price')}: {workingHoursPricing.moderateMakeupPackagePrice} {t('currency')}</label>
                            <div className="flex items-center space-x-2">
                                <Button icon="pi pi-minus" onClick={() => handleCountChange('moderateMakeupPackage', Math.max(0, moderateMakeupPackageCount - 1))} className="p-button-rounded p-button-text" />
                                <span>{moderateMakeupPackageCount}</span>
                                <Button icon="pi pi-plus" onClick={() => handleCountChange('moderateMakeupPackage', moderateMakeupPackageCount + 1)} className="p-button-rounded p-button-text" />
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <label className="text-sm font-semibold text-gray-800">{t('full_makeup_package_price')}: {workingHoursPricing.fullMakeupPackagePrice} {t('currency')}</label>
                            <div className="flex items-center space-x-2">
                                <Button icon="pi pi-minus" onClick={() => handleCountChange('fullMakeupPackage', Math.max(0, fullMakeupPackageCount - 1))} className="p-button-rounded p-button-text" />
                                <span>{fullMakeupPackageCount}</span>
                                <Button icon="pi pi-plus" onClick={() => handleCountChange('fullMakeupPackage', fullMakeupPackageCount + 1)} className="p-button-rounded p-button-text" />
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <label className="text-sm font-semibold text-gray-800">{t('dress_package_price')}: {workingHoursPricing.dressPackagePrice} {t('currency')}</label>
                            <div className="flex items-center space-x-2">
                                <Button icon="pi pi-minus" onClick={() => handleCountChange('dressPackage', Math.max(0, dressPackageCount - 1))} className="p-button-rounded p-button-text" />
                                <span>{dressPackageCount}</span>
                                <Button icon="pi pi-plus" onClick={() => handleCountChange('dressPackage', dressPackageCount + 1)} className="p-button-rounded p-button-text" />
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <label className="text-sm font-semibold text-gray-800">{t('abaya_package_price')}: {workingHoursPricing.abayaPackagePrice} {t('currency')}</label>
                            <div className="flex items-center space-x-2">
                                <Button icon="pi pi-minus" onClick={() => handleCountChange('abayaPackage', Math.max(0, abayaPackageCount - 1))} className="p-button-rounded p-button-text" />
                                <span>{abayaPackageCount}</span>
                                <Button icon="pi pi-plus" onClick={() => handleCountChange('abayaPackage', abayaPackageCount + 1)} className="p-button-rounded p-button-text" />
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <label className="text-sm font-semibold text-gray-800">{t('full_photo_session_price')}: {workingHoursPricing.fullPhotoSessionPrice} {t('currency')}</label>
                            <div className="flex items-center space-x-2">
                                <Button icon="pi pi-minus" onClick={() => handleCountChange('fullPhotoSession', Math.max(0, fullPhotoSessionCount - 1))} className="p-button-rounded p-button-text" />
                                <span>{fullPhotoSessionCount}</span>
                                <Button icon="pi pi-plus" onClick={() => handleCountChange('fullPhotoSession', fullPhotoSessionCount + 1)} className="p-button-rounded p-button-text" />
                            </div>
                        </div>
                    </>
                );
            case 'HAIR_STYLIST':
                return (
                    <>
                        <div className="flex items-center justify-between">
                            <label className="text-sm font-semibold text-gray-800">{t('moderate_hair_style_price')}: {workingHoursPricing.moderateHairStylePrice} {t('currency')}</label>
                            <div className="flex items-center space-x-2">
                                <Button icon="pi pi-minus" onClick={() => handleCountChange('moderateHairStyle', Math.max(0, moderateHairStyleCount - 1))} className="p-button-rounded p-button-text" />
                                <span>{moderateHairStyleCount}</span>
                                <Button icon="pi pi-plus" onClick={() => handleCountChange('moderateHairStyle', moderateHairStyleCount + 1)} className="p-button-rounded p-button-text" />
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <label className="text-sm font-semibold text-gray-800">{t('bride_hair_style_price')}: {workingHoursPricing.brideHairStylePrice} {t('currency')}</label>
                            <div className="flex items-center space-x-2">
                                <Button icon="pi pi-minus" onClick={() => handleCountChange('brideHairStyle', Math.max(0, brideHairStyleCount - 1))} className="p-button-rounded p-button-text" />
                                <span>{brideHairStyleCount}</span>
                                <Button icon="pi pi-plus" onClick={() => handleCountChange('brideHairStyle', brideHairStyleCount + 1)} className="p-button-rounded p-button-text" />
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <label className="text-sm font-semibold text-gray-800">{t('special_hair_style_with_extensions_price')}: {workingHoursPricing.specialHairStyleWithExtensionsPrice} {t('currency')}</label>
                            <div className="flex items-center space-x-2">
                                <Button icon="pi pi-minus" onClick={() => handleCountChange('specialHairStyleWithExtensions', Math.max(0, specialHairStyleWithExtensionsCount - 1))} className="p-button-rounded p-button-text" />
                                <span>{specialHairStyleWithExtensionsCount}</span>
                                <Button icon="pi pi-plus" onClick={() => handleCountChange('specialHairStyleWithExtensions', specialHairStyleWithExtensionsCount + 1)} className="p-button-rounded p-button-text" />
                            </div>
                        </div>
                    </>
                );
            case 'MAKEUP_ARTIST':
                return (
                    <>
                        <div className="flex items-center justify-between">
                            <label className="text-sm font-semibold text-gray-800">{t('bride_price')}: {workingHoursPricing.bridePrice} {t('currency')}</label>
                            <div className="flex items-center space-x-2">
                                <Button icon="pi pi-minus" onClick={() => handleCountChange('bride', Math.max(0, brideCount - 1))} className="p-button-rounded p-button-text" />
                                <span>{brideCount}</span>
                                <Button icon="pi pi-plus" onClick={() => handleCountChange('bride', brideCount + 1)} className="p-button-rounded p-button-text" />
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <label className="text-sm font-semibold text-gray-800">{t('bridesmaid_price')}: {workingHoursPricing.bridesmaidPrice} {t('currency')}</label>
                            <div className="flex items-center space-x-2">
                                <Button icon="pi pi-minus" onClick={() => handleCountChange('bridesmaid', Math.max(0, bridesmaidCount - 1))} className="p-button-rounded p-button-text" />
                                <span>{bridesmaidCount}</span>
                                <Button icon="pi pi-plus" onClick={() => handleCountChange('bridesmaid', bridesmaidCount + 1)} className="p-button-rounded p-button-text" />
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <label className="text-sm font-semibold text-gray-800">{t('moderate_makeup_price')}: {workingHoursPricing.moderateMakeupPrice} {t('currency')}</label>
                            <div className="flex items-center space-x-2">
                                <Button icon="pi pi-minus" onClick={() => handleCountChange('moderateMakeup', Math.max(0, moderateMakeupCount - 1))} className="p-button-rounded p-button-text" />
                                <span>{moderateMakeupCount}</span>
                                <Button icon="pi pi-plus" onClick={() => handleCountChange('moderateMakeup', moderateMakeupCount + 1)} className="p-button-rounded p-button-text" />
                            </div>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-black py-8 rounded-lg">
            <Toast ref={toast} />
            <div className="p-2 bg-black text-white rounded-lg shadow-md w-full lg:w-3/4">
                <h2 className="text-2xl font-bold mb-6 text-center">{t('book_appointments')}</h2>
                <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-8 lg:space-y-0 lg:space-x-8">
                    <div className="w-full lg:w-1/2">
                        <Calendar
                            value={date}
                            onChange={handleDateChange}
                            inline
                            className="w-full bg-[#ece5df] text-white p-4 rounded-lg shadow-md calendar-custom"
                            dateTemplate={(date) => {
                                const dateObj = new Date(date.year, date.month, date.day);
                                return (
                                    <div className={`p-2 rounded ${dayClassName(dateObj)}`}>
                                        {date.day}
                                    </div>
                                );
                            }}
                        />
                    </div>
                    <div className="w-full lg:w-1/2 flex flex-col space-y-4 lg:mr-4 bg-[#ece5df] text-gray-800 p-4 rounded-lg shadow-md">

                        <h3 className="text-xl font-semibold mb-2 text-gray-800 text-center">{t('available_and_booked_hours')}</h3>
                        <div className="flex flex-wrap justify-center gap-2 w-full">
                            {selectedAvailability.available.length === 0 && selectedAvailability.booked.length === 0 && (
                                <p className="col-span-full text-center text-gray-600">{t('no_appointments')}</p>
                            )}
                            {selectedAvailability.available.map((time, index) => (
                                <Button
                                    key={index}
                                    label={time}
                                    className={`p-button-success text-white bg-green-500 border-green-500 transition-transform transform hover:scale-105 ${selectedTime === time ? 'p-button-outlined' : ''}`}
                                    onClick={() => handleTimeSelect(time)}
                                />
                            ))}
                            {selectedAvailability.booked.map((time, index) => (
                                <Button key={index} label={time} className="p-button-danger text-white bg-red-500 border-red-500 transition-transform transform hover:scale-105" disabled />
                            ))}
                        </div>
                        <div className="flex flex-col space-y-4 mt-4">
                            {renderServiceInputs()}
                            <div className="flex justify-between items-center">
                                <label className="text-lg font-semibold text-gray-800">{t('total_price')}: {totalPrice} {t('currency')}</label>
                                <Button
                                    label={t('confirm_booking')}
                                    className="flex items-center justify-center space-x-2 space-x-reverse bg-gradient-to-r from-gray-700 to-black text-white px-4 py-2 rounded-full hover:opacity-90 transition-opacity duration-300"
                                    disabled={!selectedTime || (artistType === 'MAKEUP_ARTIST' && brideCount === 0 && bridesmaidCount === 0 && moderateMakeupCount === 0) ||
                                        (artistType === 'MODEL' && moderateMakeupPackageCount === 0 && fullMakeupPackageCount === 0 && dressPackageCount === 0 && abayaPackageCount === 0 && fullPhotoSessionCount === 0) ||
                                        (artistType === 'HAIR_STYLIST' && moderateHairStyleCount === 0 && brideHairStyleCount === 0 && specialHairStyleWithExtensionsCount === 0)}
                                    onClick={() => {
                                        if (!isUserLoggedIn) {
                                            navigate('/login');
                                        } else {
                                            handleBooking();
                                        }
                                    }}
                                />

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Dialog header={t('choose_payment_method')} visible={showDialog}
                contentStyle={{ background: 'linear-gradient(to right, #b7b7a4, #a5a58d, #636060)' }}
                headerStyle={{ background: 'linear-gradient(to right, #b7b7a4, #a5a58d, #636060)', color: 'black' }}
                onHide={() => setShowDialog(false)}>
                <div className="flex flex-col space-y-4 p-4">
                    <Dropdown value={paymentMethod} options={paymentMethods} onChange={(e) => setPaymentMethod(e.value)} placeholder={t('select_payment_method')} className="mb-4" />
                    {paymentMethod === 'Wallet' && wallet && (
                        <div className="text-center mb-4">
                            <p className="text-lg font-semibold text-gray-800">{t('current_wallet_balance')}: {wallet.balance} {t('currency')}</p>
                            <p className="text-lg font-semibold text-gray-800">{t('total_amount')}: {totalPrice} {t('currency')}</p>
                        </div>
                    )}
                    <Button label={t('confirm')} onClick={confirmBooking} className="flex items-center justify-center space-x-2 space-x-reverse bg-gradient-to-r from-gray-700 to-black text-white px-4 py-2 rounded-full hover:opacity-90 transition-opacity duration-300" />
                </div>
            </Dialog>
        </div>
    );
};

export default ArtistAppointments;
