import React, { useState, useEffect } from 'react';
import axios from 'axios';
import authService from '../services/authService';

const Card = ({ image, title, artistId }) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const token = authService.getToken();
  const customerId = authService.getUserId();

  useEffect(() => {
    const checkIfFavorite = async () => {
      try {
        const response = await axios.get(`https://api.artistarsapp.com/api/favorites/${customerId}/${artistId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setIsFavorite(response.data.isFavorite);
      } catch (error) {
        console.error('Error checking favorite status:', error);
      }
    };

    checkIfFavorite();
  }, [customerId, artistId, token]);

  const handleFavoriteToggle = async (e) => {
    e.preventDefault();
    try {
      if (isFavorite) {
        await axios.delete(`https://api.artistarsapp.com/api/favorites/${customerId}/${artistId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      } else {
        await axios.post(`https://api.artistarsapp.com/api/favorites/${customerId}/${artistId}`, null, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  return (
    <div className=" rounded-lg shadow-md overflow-hidden transform hover:scale-105 transition-transform duration-300 relative">
      <img src={image} alt={title} className="w-full sm:w-full h-32 sm:h-64 object-fill rounded-t-lg" />
      <div className="p-1 flex justify-between items-center rounded-b-lg bg-[#ece5df]">
        <h3 className="text-[8px] sm:text-lg font-bold text-black text-center ml-2 mr-2">{title}</h3>
        <button onClick={handleFavoriteToggle} className="flex items-center z-10">
          <i className={`pi ${isFavorite ? 'pi-heart-fill text-red-500 text-[10px] sm:text-[15px]' : 'pi-heart text-black text-[10px] sm:text-[15px]'}`} ></i>
        </button>
      </div>
    </div>
  );
};

export default Card;
