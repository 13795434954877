import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from '../components/Card';
import authService from '../services/authService';
import { useTranslation } from 'react-i18next';

const FavoriteArtists = () => {
  const { t } = useTranslation();
  const [favorites, setFavorites] = useState([]);
  const token = authService.getToken();
  const customerId = authService.getUserId();

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const response = await axios.get(`https://api.artistarsapp.com/api/favorites/${customerId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const approvedArtists = response.data.filter(artist => artist.approved);
        setFavorites(approvedArtists);
      } catch (error) {
        console.error('Error fetching favorites:', error);
      }
    };

    fetchFavorites();
  }, [customerId, token]);

  return (
    <div className="container mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4 text-center text-white">{t('FavoriteArtists_title')}</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {favorites.map(artist => (
          <Card
            key={artist.id}
            image={`https://api.artistarsapp.com${artist.avatar.replace("/home/ec2-user/uploads/", "/uploads/")}`}
            title={artist.fullname}
            artistId={artist.id}
          />
        ))}
      </div>
    </div>
  );
};

export default FavoriteArtists;
